import BaseService from '@/utils/base-service';
import { ApiResponse, ApiResponseList, UseCaseListDto, UseCaseListFilter } from '@/models';
import { filterToQueryString } from './utils';

/**
 * @name UseCaseService
 * @description This class is used to query the backend server for use case information.
 */
export class UseCaseService extends BaseService {
    constructor() {
        super('use-cases');
    }

    /**
     * Get all use cases. Can throw axios exceptions.
     * @param workflowId
     */
    public async getUseCases (filter: UseCaseListFilter | undefined = undefined):
    Promise<ApiResponse<ApiResponseList<UseCaseListDto>>> {
        const queryString = filterToQueryString(filter);
        return (await this.api.get<ApiResponse<ApiResponseList<UseCaseListDto>>>(
            `${this.endpoint}/${queryString}`
        )).data;
    }
}
