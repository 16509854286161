import { Filters } from '@/models/filters'

const key = 'Favorites';

export const persistFavoriteFilters = (filters: Filters): void => {
    localStorage.setItem(key, JSON.stringify(filters));
}

export const getPersistedFavoriteFilters = (): any => {
    const filters = localStorage.getItem(key);
    if (!filters || filters == 'undefined') return {};
    return JSON.parse(filters);
}

export const getPersistedFilterIdsByName = (filterName: string): any => {
    const filters = getPersistedFavoriteFilters();
    if (!Object.keys(filters).length)
        return [];
    return filters[filterName];
}
