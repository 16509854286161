<template>
    <div class="teaser-placeholder">
        <img
            :src="teaserPlaceholderSvg"
            alt="no teaser found"
        >
        <div class="teaser-placeholder__title">
            {{ $t('document.noImage') }}
        </div>
    </div>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';
import teaserPlaceholderSvg from '@/assets/svg/teaser-placeholder.svg';

@Component({ name: 'teaser-placeholder' })
export default class TeaserPlaceholder extends Vue {
    private teaserPlaceholderSvg = teaserPlaceholderSvg;
}
</script>

<style scoped lang="less">
@import '../../variables.less';

.teaser-placeholder {
    width: 100%;
    height: 100%;
    background: @warm-grey-25;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}

.teaser-placeholder__title {
    font-size: 2rem;
    color: @dark-grey;
}
</style>