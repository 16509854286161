<template>
    <div class="notification">
        <div class="flex-container">
            <div class="flex-container banner">
                <span class="icon-container">
                    <pui-icon
                        class="mr-1"
                        :icon-name="icon"
                        :color="iconColor"
                        size="22px"
                    />
                </span>


                <div class="content">
                    <span class="title">{{ title }}</span>
                    <div
                        v-if="$slots.default"
                        class="content__description mt-1"
                    >
                        <slot />
                    </div>
                </div>
            </div>
            <pui-icon
                v-if="canBeClosed"
                class="close-icon"
                icon-name="close"
                size="22px"
                @click.native="$emit('close')"
            />
        </div>
    </div>
</template>

<script lang="ts">

import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'inline-notification',
    components: { },
})
export default class InlineNotification extends Vue {
    @Prop({ type: String, default: '' }) readonly title!: string;
    @Prop({ type: String, default: '' }) readonly icon!: string;
    @Prop({ type: String, default: '' }) readonly iconColor!: string;
    @Prop({ type: Boolean, default: true }) readonly canBeClosed!: string;
}
</script>

<style lang="less" scoped>
@import '../../variables.less';

.notification {
    background-color: #99DFFF1A;
    border: 1px solid #0078DC40;
    border-radius: 4px;
    padding: 1rem;

    .banner {
        width: 100%;
    }

    .icon-container {
        height: 100%;
    }

    .title {
        font-family: Roboto-Medium, sans-serif;
    }

    .content {
        padding-top: 0.25rem;
    }

    .close-icon {
        cursor: pointer;
        padding-top: 0.1rem;
    }
}
</style>
