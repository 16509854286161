import BaseService from '@/utils/base-service';
import { ApiResponse } from '@/models';
import { NotificationSettings } from '@/models/settings';

export class SettingsService extends BaseService {
    constructor() {
        super('user-settings');
    }

    public async getSettings(): Promise<ApiResponse<NotificationSettings>> {
        return (await this.api.get<ApiResponse<NotificationSettings>>(`${this.endpoint}`)).data;
    }

    public async saveSettings(settings: NotificationSettings): Promise<ApiResponse<NotificationSettings>> {
        return (await this.api.post<ApiResponse<NotificationSettings>>(`${this.endpoint}`, settings)).data;
    }
}
