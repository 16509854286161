<template>
    <pui-loader :promise="loaderPromise">
        <div v-if="documents.length">
            <documents-list-item
                v-for="(document, $index) in documents"
                :key="document.id"
                :document="document"
                class="mb-2"
                :class="{'mt-2': $index > 0}"
            />

            <documents-pagination
                :total-count="pagination.totalCount"
                :page-count="pagination.totalPages"
                class="pagination pt-1 pb-1"
            />
        </div>
        <pui-loader-error
            v-else-if="loaded"
            :title="$t('noLearnings')"
            icon="error-empty-data"
            :buttons="actionButtons"
        />
    </pui-loader>
</template>

<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import DocumentsListItem from '@/components/document/overview-document-list-card.vue';
import DocumentsPagination from '@/components/document/documents-pagination.vue';
import { Document, GetDocumentsQuery, Pagination } from '@/models';
import { mapGetters, mapState } from 'vuex';
import { LocaleMessage } from 'vue-i18n';

@Component({
    name: 'documents-list',
    components: { DocumentsListItem, DocumentsPagination },
    computed: {
        ...mapState('document', ['documents', 'pagination']),
        ...mapGetters('user', ['IS_CREATOR']),
    },
})
export default class DocumentsList extends Vue {
    private $pui!: { toast: Function };
    private readonly documents!: Document[];
    private readonly pagination!: Pagination;
    private readonly IS_CREATOR!: boolean;
    private loaded = false;
    private loaderPromise: any = null;
    private previousRouteQuery: any = null;

    get actionButtons(): { label: LocaleMessage; onClick: Function; state?: string }[] {
        const actions = [];
        if (this.IS_CREATOR) {
            actions.push(
                { label: this.$t('actions.addLearning'), onClick: () => this.$router.push({ name: 'DocumentAdd' }) }
            );
        }
        return actions;
    }

    private async mounted(): Promise<void> {
        this.previousRouteQuery = null;
        await this.getDocuments(this.$route.query as any);
    }

    @Watch('$route.query')
    private async getDocuments(query: GetDocumentsQuery): Promise<void> {
        if (JSON.stringify(query) === JSON.stringify(this.previousRouteQuery)) {
            return;
        }
        this.previousRouteQuery = query;
        this.$store.commit('loading');
        this.loaderPromise = this.$store
            .dispatch('document/GET_DOCUMENTS', query)
            .catch(error => {
                this.$pui.toast({
                    type: 'error',
                    title: this.$t('errorLoadingDocuments'),
                    copy: error.message,
                });
            })
            .finally(() => {
                this.loaded = true;
                this.$store.commit('loading');
            })
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';

.pagination {
    position: sticky;
    bottom: 0;
    background-color: @white;
    display: flex;
    justify-content: center;
}
</style>
