import { ApiNotificationResponseList, ApiResponse } from '@/models';
import { Notification, NotificationFilterModel } from '../store/modules/contracts/Notification';
import { sdk } from '@/utils/fe-sdk/sdk';

export class NotificationService {
    protected endpoint = 'notifications';
    protected api = sdk?.core.metaData.request.api;

    public async getAllNotifications(notificationFilters: NotificationFilterModel):
        Promise<ApiResponse<ApiNotificationResponseList<Notification>>> {

        let queryString = '?';

        for (const field in notificationFilters) {
            if (notificationFilters[field]) {
                queryString = queryString.concat(`${field}=${notificationFilters[field]}&`);
            }
        }

        if (queryString.endsWith('&') || queryString.endsWith('?')) {
            queryString = queryString.slice(0, -1)
        }
        return (await this.api.get<ApiResponse<ApiNotificationResponseList<Notification>>>
        (`/${this.endpoint}${queryString}`)).data;
    }

    public async clearAllNotifications(): Promise<number> {
        return (await this.api.post(`/${this.endpoint}/clear-all`)).status;
    }

    public async clearNotificationsByList(notificationIds: number[]): Promise<number> {
        return (await this.api.post(`/${this.endpoint}/clear`, notificationIds)).status;
    }

    public async clearNotificationById(notificationId: number): Promise<number> {
        return (await this.api.post(`/${this.endpoint}/${notificationId}/clear`)).status;
    }

    public async doAction(workflowInstanceId: string, workflowActionId: number, content?: any): Promise<number> {
        if (!content) {
            return (await this.api.post(
                `/workflows/${workflowInstanceId}`,
                { workflowActionId, content: {} })).status;
        } else {
            return (await this.api.post(
                `/workflows/${workflowInstanceId}`,
                { workflowActionId, content: content })).status;
        }
    }

    public async markNotificationAsRead(notificationId: number): Promise<number> {
        return (await this.api.post(`/${this.endpoint}/${notificationId}/mark-as-read`)).status;
    }

    public async markNotificationsAsRead(notificationIds: number[]): Promise<number> {
        return (await this.api.post(`/${this.endpoint}/mark-as-read`, notificationIds)).status;
    }
}
