import { DocumentStatus } from '@/models/document';

const colors: { [key: string]: string } = {
    Low: '#FFFD37',
    Medium: '#FDA428',
    Severe: '#FC0D1B',
    NotApplicable: 'darkgray'
}


export const getBadgeColors = (prop: string): { [key: string]: string } => {
    return { backgroundColor: colors[prop], textColor: prop === 'Low' ? '#4a4a4a' : 'white' };
};

const reviewStatuscolors: { [key: string]: string } = {
    [DocumentStatus.Draft] : '#0078dc',
    [DocumentStatus.Published]: '#94ce58',
    [DocumentStatus.PendingTranslation]: '#9747FF',
    [DocumentStatus.Verified]: '#4bb7a8',
    [DocumentStatus.NotRelevant]: '#fc0d1b',
}

export const getreviewStatusBadgeColors = (prop: string): { [key: string]: string } => {
    return { backgroundColor: reviewStatuscolors[prop], textColor: 'white' };
};
