import BaseService from '@/utils/base-service';
import { ApiResponse, Category } from '@/models';

/**
 * @name LocationService
 * @description This class is used to query API for data related to App needs,
 */
export class LocationService extends BaseService {
    constructor() {
        super('location');
    }

    public async getAll(): Promise<ApiResponse<Category[]>> {
        return (await this.api.get<ApiResponse<Category[]>>(`${this.endpoint}/all`)).data;
    }
}
