<template>
    <div class="mt-2">
        <pui-link
            :title="$t('actions.backToOverview')"
            class="action-bar__action"
            icon="revert"
            @click="$router.push({ name: 'Home', query: { ...previousQuery } })"
        />
        <div class="flex-container flex-center mt-2">
            <pui-headline
                class="mr-3"
                type="h3"
            >
                {{ $t('settings.alertSettings') }}
            </pui-headline>
            <pui-toggle
                v-pui-tooltip="{ message: $t('actions.turnOffAlerts') }"
                :checked="settings.areAlertsDisabled"
                @change="value => settings.areAlertsDisabled = value"
                :toggle-label-colors="true"
            >
                <template
                    slot="puiToggleRight"
                >
                    <span>
                        {{ $t('actions.disableAllAlerts') }}
                    </span>
                </template>
            </pui-toggle>
        </div>

        <div
            class="card mt-3 flex-col container"
        >
            <span class="mb-1 section-title">{{ $t('settings.receiveNotificationsFor') }}</span>
            <pui-form-input-selector
                class="mt-1"
                :class="{'learnings-filter-type--disabled' : settings.areAlertsDisabled}"
                name="learningsFilterType"
                :options="filteringOptions"
                :selected-value.sync="alertType"
                @update:selected-value="handleFilterOptionChanged"
            />
            <span class="mb-1 mt-3 section-title">{{ $t('settings.receiveNotificationsLanguage') }}</span>
            <pui-form-input-selector
                class="mt-1"
                :class="{'learnings-filter-type--disabled' : settings.areAlertsDisabled}"
                name="notificationsLanguage"
                :options="notificationsLanguageOptions"
                :selected-value.sync="settings.selectedLanguage"
                @update:selected-value="handleNotificationsLanguageChange"
            />
            <template v-if="!settings.receiveAllAlerts">
                <settings-filters
                    v-if="!settings.areAlertsDisabled"
                    class="mt-3"
                />
                <applied-filters-list
                    class="applied-filters mt-2"
                    :is-disabled="settings.areAlertsDisabled"
                    @clearAll="clearAllFilters"
                />
            </template>
            <hr class="mt-2 mb-3">
            <span class="mb-1 section-title">
                {{ $t('settings.howOftenWouldYouLikeToReceiveNotifications') }}
            </span>
            <pui-form-select
                class="recurrence-selector"
                label=""
                :options="notificationRecurrenceOptions"
                :is-disabled="settings.areAlertsDisabled"
                v-model="settings.notificationTimeline"
            />
            <hr class="mt-2 mb-3">
            <span class="mb-1 section-title">{{ $t('settings.receiveNotificationsWhen') }}</span>
            <pui-form-checkbox
                v-for="(item, index) in typeOfLearnings"
                :key="index"
                :label="item.label"
                :checked="settings[item.key]"
                :is-disabled="settings.areAlertsDisabled"
                @change="value => settings[item.key] = value"
            />
        </div>
        <div class="flex-container justify-end mt-2">
            <pui-button
                @click="openSaveFiltersModal"
            >
                {{ $t('actions.save') }}
            </pui-button>
        </div>
        <save-filters-modal
            :title="confirmModalTitle"
            :description="confirmModalDescription"
            ref="confirmFiltersModal"
            @confirm="handleSaveUserFilters"
        />
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import AppliedFiltersList from '@/components/filters/applied-filters-list.vue';
import SaveFiltersModal from '@/components/filters/save-filters-modal.vue';
import { capitalizeFilters, initializeFilters } from '@/utils/filters-helper';
import { mapGetters } from 'vuex';
import { getnotificationTimelineTypes, NotificationSettings, TimelineType } from '@/models/settings';
import { SettingsService } from '@/services/settings-service';
import SettingsFilters from '../filters/settings-filters.vue';
import { FiltersService } from '@/services/filters-service';
import { Route, NavigationGuardNext } from 'vue-router';

const filteringOptions = {
    ALL_LEARNINGS: 'settings.allLearnings',
    MY_SELECTION: 'settings.mySelection'
}

@Component({
    components: {
        AppliedFiltersList,
        SaveFiltersModal,
        SettingsFilters,
    },
    computed: {
        ...mapGetters({
            FILTERS: 'filters/FILTERS',
        }),
    },
})
export default class Settings extends Vue {

    private readonly FILTERS!: any;
    private $pui!: { toast: Function };
    private notificationRecurrenceOptions = getnotificationTimelineTypes();
    private settingsService = new SettingsService();
    private settings = {
        selectionIsPublished: false,
        selectionHasChanged: false,
        selectionIsCommented: false,
        areAlertsDisabled: false,
        selectedLanguage: 'en-US',
        notificationTimeline: TimelineType.Daily,
        receiveAllAlerts: true
    }
    private availableLanguageCodes = ['en-US', 'de-DE', 'sv-SE'];

    private filtersService = new FiltersService();

    private alertType = this.$t(filteringOptions.ALL_LEARNINGS) as string;
    private previousQuery: any = { page: '1', Sort: 'Date' };

    private async mounted(): Promise<void> {
        this.$store.commit('loading');
        try {
            await this.loadPreSavedFilters();
            const {result} = await this.settingsService.getSettings();
            this.setSettings(result);
        } catch (error) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('errorLoad'),
            });
        } finally {
            this.$store.commit('loading');
        }
    }

    private async loadPreSavedFilters(): Promise<void> {
        const { result } = await this.filtersService.getFilters();

        if (Object.keys(result).length > 0) {
            const filters = initializeFilters(capitalizeFilters((result)));
            await this.$store.dispatch('filters/UPDATE_FILTERS_LOCALLY', filters);
        }
    }

    private setSettings(settings: NotificationSettings): void {
        const {
            selectionIsPublished,
            selectionHasChanged,
            selectionIsCommented,
            notificationTimeline,
            selectedLanguage,
            areAlertsDisabled,
            receiveAllAlerts
        } = settings;

        this.settings.selectionIsPublished = selectionIsPublished ?? false;
        this.settings.selectionHasChanged = selectionHasChanged ?? false;
        this.settings.selectionIsCommented = selectionIsCommented ?? false;
        this.settings.notificationTimeline = notificationTimeline ?? TimelineType.Daily;
        this.settings.areAlertsDisabled = areAlertsDisabled ?? false;
        this.settings.selectedLanguage = this.availableLanguageCodes.find(c => c.includes(selectedLanguage)) ?? 'en-US';
        this.settings.receiveAllAlerts = receiveAllAlerts ?? true;
        this.alertType = this.settings.receiveAllAlerts
            ? filteringOptions.ALL_LEARNINGS
            : filteringOptions.MY_SELECTION
        ;
    }

    private typeOfLearnings= [
        {
            key: 'selectionIsPublished',
            label: this.$t('settings.newLearningsPublished') as string,
            value: false
        },
        {
            key: 'selectionHasChanged',
            label: this.$t('settings.learningsChanged') as string,
            value: false
        },
        {
            key: 'selectionIsCommented',
            label: this.$t('settings.learningsCommented') as string,
            value: false
        }
    ];

    private filteringOptions = [
        {
            label: this.$t(filteringOptions.ALL_LEARNINGS) as string,
            value: filteringOptions.ALL_LEARNINGS,
        },
        {
            label: this.$t(filteringOptions.MY_SELECTION) as string,
            value: filteringOptions.MY_SELECTION,
        }
    ];

    private notificationsLanguageOptions = this.availableLanguageCodes.map(code => ({
        label: this.$t(`languages.${code}`),
        value: code
    }));

    private handleFilterOptionChanged(value: string): void {
        this.alertType = value;
        this.settings.receiveAllAlerts = (value === filteringOptions.ALL_LEARNINGS);
    }

    private handleNotificationsLanguageChange(value: string): void {
        this.settings.selectedLanguage = value;
    }

    beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
        next((vm) => {
            Object.assign((vm as Settings).previousQuery, from.query);
        });
    }

    private async handleSaveUserFilters(): Promise<void> {
        this.$store.commit('loading');
        try {
            await this.$store.dispatch('filters/UPDATE_FILTERS', this.FILTERS);
            await this.settingsService.saveSettings(this.settings);
            this.$pui.toast({
                type: 'success',
                title: this.$t('settings.successfullyUpdatedAlerts'),
                copy: '',
            });
        } catch (error) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('settings.errorUpdatingAlerts'),
                copy: '',
            });
        } finally {
            this.$store.commit('loading');
        }
    }

    private openSaveFiltersModal(): void {
        (this.$refs.confirmFiltersModal as any).open();
    }

    private get confirmModalTitle(): string {
        return this.settings.areAlertsDisabled
            ? this.$t('actions.disableAllAlerts') as string
            : this.$t('settings.saveFiltersModal.setAlertsForLearnings') as string
        ;
    }

    private get confirmModalDescription(): string {
        return this.settings.areAlertsDisabled
            ? this.$t('settings.saveFiltersModal.disableAlertsDescription') as string
            : this.$t('settings.saveFiltersModal.description') as string
        ;
    }

    private clearAllFilters(): void {
        this.$store.dispatch('filters/UPDATE_FILTERS_LOCALLY', initializeFilters());
    }
}

</script>

<style scoped lang="less">
@import '../../variables.less';

.section-title {
    font-family: Roboto-Medium, sans-serif;
}

.recurrence-selector {
    width: 28rem;
}

hr {
    border: 0;
    width: 100%;
    background-color: @warm-grey;
    height: 1px;
}

.container {
    padding: 4rem;
}

.applied-filters {
    padding: 2rem;
    border: 1px solid #eceaea;
    border-radius: 0.5rem;
}

/deep/ .learnings-filter-type--disabled {
    pointer-events: none;

    input:checked ~ .pui-form-input-selector-option__inner {
        border: 1px solid @warm-grey;
        background-color: @grey-lighter;
        font-weight: 500;
    }

    input ~ .pui-form-input-selector-option__inner {
        border: 1px solid @warm-grey;
        background-color: @pale-grey;
        font-weight: 500;
    }
}
</style>
