<template>
    <debounce-input
        data-cy="filterSearch"
        :placeholder-text="$t('actions.searchLearning')"
        :trailing-icon-name="!!termProxy? 'close' : 'search'"
        :trailing-icon-is-clickable="!!termProxy"
        :debounce-length="1"
        @trailing-icon:click="reset"
        v-model.trim="termProxy"
    />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DebounceInput from '@/components/debounce-input/debounce-input.vue';

@Component({
    name: 'search-term',
    components: { DebounceInput },
})
export default class SearchTerm extends Vue{
    private get termProxy(): string {
        return this.$route.query.term ? this.$route.query.term as string : '';
    }
    private set termProxy(newVal) {
        this.$router.push({
            query: {
                ...this.$route.query,
                page: '1',
                term: newVal,
            },
        });
    }

    private reset(): void {
        const query = { ...this.$route.query };
        delete query.term;
        this.$router.push({ query });
    }
}
</script>
