import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex';
import { document } from '@/store/modules/document';
import { user } from '@/store/modules/user';
import { category } from '@/store/modules/category';
import { location } from '@/store/modules/location';
import { fleet } from '@/store/modules/fleet';
import { hazard } from '@/store/modules/hazard';
import { consequence } from '@/store/modules/consequence';
import { notifications } from '@/store/modules/notifications';
import { useCases } from '@/store/modules/use-cases';
import { filters } from '@/store/modules/filters';
Vue.use(Vuex);

export interface ApplicationState {
    isLoading: boolean;
    isProductionEnv: boolean;
}

const applicationState: ApplicationState = {
    isLoading: false,
    isProductionEnv: process.env.VUE_APP_ENVIRONMENT === 'prd',

};

const getters: GetterTree<ApplicationState, ApplicationState> = {
    isLoading(state): boolean {
        return state.isLoading;
    },
    isProductionEnv(state: typeof applicationState): boolean {
        return state.isProductionEnv;
    }

};

const mutations: MutationTree<ApplicationState> = {
    loading(state): void {
        state.isLoading = !state.isLoading;
    }

};

const actions: ActionTree<ApplicationState, ApplicationState> = {};

export default new Vuex.Store({
    modules: {
        document,
        user,
        category,
        location,
        fleet,
        hazard,
        consequence,
        notifications,
        useCases,
        filters
    },
    state: applicationState,
    getters,
    mutations,
    actions,
});
