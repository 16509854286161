<template>
    <button-like
        :like="document.like"
        class="pr-0"
        :disabled="loading"
        @click.native="toggleLike"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import ButtonLike from '@/components/button-like/button-like.vue';
import { Document } from '@/models';

@Component({
    name: 'button-like-toggle',
    components: { ButtonLike },
})
export default class ButtonLikeToggle extends Vue {
    @Prop(Object) readonly document!: Document;
    private loading = false;

    private async toggleLike(): Promise<void> {
        this.loading = true;
        try {
            await this.$store.dispatch('document/TOGGLE_LIKE', {
                documentId: this.document.id,
                like: !this.document.like,
            });
        } catch (error) {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('errorLikingDocument'),
                copy: error.message,
            });
        } finally {
            this.loading = false;
        }
    }
}
</script>
