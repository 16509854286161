<template>
    <button>
        <img
            :src="`/img/icons/${icon}.svg`"
            :alt="icon"
            :title="$t('actions.likeUnlike')"
        >
    </button>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'ButtonLike',
})
export default class ButtonLike extends Vue {
    @Prop({ type: Boolean, default: false }) readonly like!: boolean;

    private get icon(): string {
        return this.like ? 'thumbUp_clicked' : 'thumbUp';
    }
}
</script>
<style scoped>
button:disabled {
    opacity: .5;
}
img {
    width: 18px;
    height: 18px;
}
</style>
