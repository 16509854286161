import { sdk } from './fe-sdk/sdk';

export default class BaseService {
    protected api;
    protected endpoint = '';

    constructor(endpoint: string) {
        this.endpoint = endpoint;
        this.api = sdk?.backend.request.api;
    }
}
