<template>
    <pui-pagination
        v-model="currentPage"
        :total-pages="pageCount"
        :range-of-pages="rangeOfPages"
        class="documents-pagination"
        @changed:page="onPageChanged"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils';

@Component({
    name: 'documents-pagination',
})
export default class DocumentsPagination extends Vue {
    @Prop(Number) readonly totalCount!: number;
    @Prop(Number) readonly pageCount!: number;

    private rangeOfPages = 7;

    onPageChanged(): void{
        EventBus.$emit(EventBus.GLOBAL.SCROLL_TOP, true);
    }

    get currentPage(): number {
        return Number(this.$route.query.page);
    }

    set currentPage(page) {
        this.$router.push({
            query: {
                ...this.$route.query,
                page: String(page),
            }
        });
    }
}

</script>