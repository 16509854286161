import { sdk } from '@/utils/fe-sdk/sdk';

export interface NotificationSettings {
    selectionIsPublished: boolean;
    selectionHasChanged: boolean;
    selectionIsCommented: boolean;
    notificationTimeline: TimelineType;
    areAlertsDisabled: boolean;
    selectedLanguage: string;
    receiveAllAlerts: boolean;
}

export enum TimelineType {
    Daily = 0,
    Immediately = 1,
    Weekly = 2
}

export const getnotificationTimelineTypes = (): { label: string; value: string } [] => {
    const { translate: t } = sdk?.translation.uiTranslation;

    return [
        {
            label: t('settings.daily') as string,
            value: 'Daily'
        },
        {
            label: t('settings.immediately') as string,
            value: 'Immediately'
        },
        {
            label: t('settings.weekly') as string,
            value: 'Weekly'
        },
    ]
}
