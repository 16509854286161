/**
 * Collection of helper methods for handling local storage items.
 * Inspired by Coode Master Data.
 */
export class LocalStorageHelper {

    public static setSelectedLanguage(val: any): void {
        localStorage.setItem(LocalStorageHelper.keys.selectedLanguage, JSON.stringify(val));
    }

    public static setLanguageOptions(options: string[]): void {
        localStorage.setItem(LocalStorageHelper.keys.languageOptions, JSON.stringify(options));
    }

    public static getSelectedLanguage(): string | null {
        const languageString = localStorage.getItem(LocalStorageHelper.keys.selectedLanguage);
        if (languageString !== null) {
            return JSON.parse(languageString);
        }
        return null;
    }

    public static getLanguageOptions(): string[] {
        const languageOptions = localStorage.getItem(LocalStorageHelper.keys.languageOptions);
        if (languageOptions !== null) {
            return JSON.parse(languageOptions);
        }
        return [];
    }

    public static determineDefaultLanguage(): string {
        LocalStorageHelper.getLanguageOptions().find((el: string) => {
            if (el === navigator.language) {
                return el;
            }
        });
        return LocalStorageHelper.getLanguageOptions()[0];
    }

    private static keys = {
        selectedLanguage: 'coode.lfe.selectedLanguage',
        languageOptions: 'coode.lfe.languageOptions',
    };
}
