<template>
    <div v-if="numberOfAppliedFilters">
        <div class="flex-container flex-center mb-2">
            <span class="summary-label">
                {{ `${$t('settings.summary')}: ${numberOfAppliedFilters} ${$t('settings.filtersSelected')}` }}
            </span>
            <pui-button
                v-if="numberOfAppliedFilters"
                small
                class="ml-4 clear-button"
                icon="reset"
                :disabled="isDisabled"
                @click="clearAllFilters"
            >
                {{ $t('actions.clearAllFilters') }}
            </pui-button>
        </div>
        <div
            v-for="(filter, index) in initializedSelectedFilters"
            :key="index"
            class="mb-4"
        >
            <span class="filter mr-2">{{ $t(`document.${filter.label.toLowerCase()}`) }}:</span>
            <span class="text-muted">{{ `${filter.appliedValues.length} ${$t('settings.filtersSelected')}` }}</span>
            <div class="mb-2">
                <template
                    v-for="(applied, idx) in filter.appliedValues"
                >
                    <pui-badge
                        :key="idx"
                        v-if="idx < filter.maxVisibleItems"
                        class="mr-1 mt-1"
                        :background-color="isDisabled ? '#ADADAD' : '#135B8B'"
                    >
                        {{ applied }}
                    </pui-badge>
                </template>
            </div>
            <pui-link
                v-if="filter.maxVisibleItems < filter.appliedValues.length"
                :title="$t('actions.showMore')"
                @click="handleExpandOrCollapseFilter(filter, filter.appliedValues.length)"
            />
            <pui-link
                v-else-if="filter.appliedValues.length > maxVisibleOptions"
                :title="$t('actions.showLess')"
                @click="handleExpandOrCollapseFilter(filter, maxVisibleOptions)"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import {
    FILTERS,
    getAllAppliedFilterKeys,
    getReviewStatusOptions,
    riskAndSeverityOptions
} from '@/utils/filters-helper';
import { Category, Profile } from '@/models';
import { FiltersService } from '@/services/filters-service';
import { Filters } from '@/models/filters';
import { numberOfAppliedFilters } from '@/utils/filters-helper';

@Component({
    name: 'aplied-filters-list',
    computed: {
        ...mapState('document', ['editors']),
        ...mapState('category', ['categories']),
        ...mapState('location', ['locations']),
        ...mapState('fleet', ['fleets']),
        ...mapState('hazard', ['hazards']),
        ...mapState('consequence', ['consequences']),
        ...mapGetters({
            filters: 'filters/FILTERS',
        }),

    },
})
export default class AppliedfiltersList extends Vue {

    @Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;

    private readonly filters!: any;
    private categories!: Category[];
    private locations!: Category[];
    private fleets!: Category[];
    private hazards!: Category[];
    private consequences!: Category[];
    private readonly editors!: Profile[];
    private filtersService = new FiltersService();
    private userFilters: Filters = {};

    private maxVisibleOptions = 20;

    private riskAndSeverityOptions = this.mapTranslatedOptions(Object.values(riskAndSeverityOptions));
    private reviewStatusOptions = this.mapTranslatedOptions(Object.values(getReviewStatusOptions()));

    private initializedSelectedFilters: any[] = [];

    @Watch('filters', {immediate: true})
    private filtersLoaded(): void {
        this.initializedSelectedFilters = getAllAppliedFilterKeys(this.filters).map(key => ({
            label: key,
            appliedValues: this.getValuesOfFilter(key),
            maxVisibleItems: this.maxVisibleOptions
        }));
    }

    private mapTranslatedOptions(list: Category[]): Category[] {
        return list.map((item) => {
            return {
                ...item,
                name: this.$tc(item.name)
            };
        });
    }

    private handleExpandOrCollapseFilter(filter: any, maxItems: number): void {
        this.initializedSelectedFilters.forEach(applied => {
            if (applied.label === filter.label) {
                applied.maxVisibleItems = maxItems
            }
        })
    }

    private findNameByIdRecursively(id: string | number, options: any[]): string | null {
        for (const item of options) {
            if (item.id == id) {
                return item.name;
            }

            if (item.children && item.children.length > 0) {
                const result = this.findNameByIdRecursively(id, item.children);
                if (result) {
                    return result;
                }
            }
        }
        return null;
    }

    private getValuesOfFilter(filter: string): any[] {
        const applied = this.filters[filter];
        const allOptions = this.getOptionsOfAppliedValues(filter);
        return [...applied].map(
            option => this.findNameByIdRecursively(option, allOptions)
        ).filter(applied => applied !== null);
    }

    private get editorsOptions(): any[] {
        return this.editors.map(({ name, userKid }) => ({ name: name!, id: userKid! }));
    }

    private getOptionsOfAppliedValues(filter: string): any[] {
        switch(filter) {
        case FILTERS.CATEGORY:
            return this.categories
        case FILTERS.CONSEQUENCE:
            return this.consequences
        case FILTERS.FLEET:
            return this.fleets
        case FILTERS.HAZARD:
            return this.hazards
        case FILTERS.LOCATION:
            return this.locations
        case FILTERS.EDITOR:
            return this.editorsOptions
        case FILTERS.REVIEWSTATUS:
            return this.reviewStatusOptions
        default:
            return this.riskAndSeverityOptions
        }
    }

    private get numberOfAppliedFilters(): number {
        return numberOfAppliedFilters(this.filters);
    }

    private clearAllFilters(): void {
        this.$emit('clearAll');
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.filter {
    font-family: Roboto-Medium, sans-serif;
}

.text-muted {
    color: @warm-grey;
}

.summary-label {
    color: @dark-grey;
}

/deep/ .clear-button {
    text-transform: none;

    svg {
        width: 24px;
        height: 24px;
        max-height: unset;
        max-width: unset;
        margin-right: 0.4rem;
    }
}
</style>
