import './utils/class-component-hooks'
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy, faCaretRight, faCaretUp, faCaretDown, faSort, faGripLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import 'adam.ui-core/dist/umd';
import 'adam.ui-core/utils/scaling.css';
import './registerServiceWorker';
import { VNode } from 'vue/types/umd';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { sdk } from '@/utils/fe-sdk/sdk';
import '/public/styles/editor-style.css';
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

library
    .add(faCopy as any, faCaretRight as any, faCaretUp as any, faCaretDown as any, faSort as any, faGripLines as any);

Vue.use(VueI18n);

const lang = sdk?.translation.uiTranslation.currentLanguage;
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

Vue.use(PebbleUI, {
    lang,
    dateTimeLocale: lang,
    dateTimeTimeZone: userTimezone, // all IANA time zones are valid
});

sdk?.auth.init().then(async () => {
    const translations = await sdk?.translation.uiTranslation.fetchInitialData();
    const i18n = new VueI18n({
        locale: lang,
        messages: translations,
    });
    new Vue({
        i18n,
        router,
        store,
        render: (h): VNode => h(App),
    }).$mount('#app');
})
