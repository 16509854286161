import Vue from 'vue';

interface Eventbus extends Vue{
    GLOBAL: {
        SHOW_SNACKBAR: string;
        SCROLL_TOP: string;
    };
    AUTO_TRANSLATE: string;
}

export const EventBus: Eventbus = new Vue({
    data(): any {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show-snackbar',
                SCROLL_TOP: 'scroll-top'
            },
            AUTO_TRANSLATE: 'auto-translate-learning-content'
        };
    },
});
