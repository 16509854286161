import { render, staticRenderFns } from "./settings-filters.vue?vue&type=template&id=6acf3e18&scoped=true"
import script from "./settings-filters.vue?vue&type=script&lang=ts"
export * from "./settings-filters.vue?vue&type=script&lang=ts"
import style0 from "./settings-filters.vue?vue&type=style&index=0&id=6acf3e18&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6acf3e18",
  null
  
)

export default component.exports