<template>
    <div>
        <adam-header-bar
            :username="user.name"
            :emailaddress="user.email"
            @apps-click="clickDrawer"
            :show-notifications.prop="showNotifications"
            :notifications.prop="notifications"
            :header-notifications-text.prop="$t('notifications')"
            :header-no-new-notifications-text.prop="$t('noNewNotifications')"
            :empty-history-desc-text.prop="$t('noNewNotificationsMessage')"
            :history-button-text.prop="$t('viewNotificationsHistory')"
            @notification-click="handleRedirect"
            @notification-approve-click="handleApprove"
            @notification-reject-click="handleReject"
            @notification-redirect-click="handleRedirect"
            @view-history-click="redirectToAppStore"
            :class="[
                { 'bg-prd': isProductionEnv },
                { 'bg-uat': isUatEnv },
                { 'bg-dev': isDevelopmentEnv },
            ]"
        >
            <span
                slot="headerContent"
                class="app-logo"
            >
                <a href="/">
                    <img
                        class="app-logo-img"
                        src="~adam.ui-core/assets/brand/logo-coode-white.svg"
                    >
                </a>
            </span>
            <span slot="headerContent">
                <h4 class="title-class">{{ $t('appTitle') }}</h4>
            </span>
            <div slot="right">
                <search-term
                    v-if="isOnOverviewPage"
                    class="search-bar mr-2"
                />
                <pui-form-select
                    :options="languageOptions"
                    :value="$i18n.locale"
                    :search-input-placeholder="$t('language.selectLanguage')"
                    label=""
                    class="language-selector"
                    @change="changeLanguage"
                />
            </div>

            <div slot="profileDetails">
                <user-profile :is-prod-env="isProductionEnv" />
            </div>
            <span
                slot="notificationsHeader"
                class="notification-header"
            >
                <pui-context-menu class="notification-header__context-menu">
                    <pui-context-menu-item
                        :key="0"
                        @click.native="clearAllNotifications"
                        :disabled="false"
                        :label="$t('notificationHeaderContextMenu.clear')"
                        class="notification-header__context-menu__item"
                    >
                        <pui-icon
                            slot="default"
                            icon-name="delete"
                            size="35px"
                            class="notification-header__context-menu__item--icon"
                        />
                    </pui-context-menu-item>
                    <pui-context-menu-item
                        :key="1"
                        :disabled="false"
                        @click.native="goToSettings"
                        :label="$t('notificationHeaderContextMenu.settings')"
                        class="notification-header__context-menu__item"
                    >
                        <pui-icon
                            slot="default"
                            icon-name="settings"
                            size="35px"
                            class="notification-header__context-menu__item--icon"
                        />
                    </pui-context-menu-item>
                    <pui-context-menu-item
                        :key="2"
                        @click.native="redirectToAppStore"
                        :disabled="false"
                        :label="$t('notificationHeaderContextMenu.open')"
                        class="notification-header__context-menu__item"
                    >
                        <pui-icon
                            slot="default"
                            icon-name="folder-open"
                            size="35px"
                            class="notification-header__context-menu__item--icon"
                        />
                    </pui-context-menu-item>
                </pui-context-menu>
            </span>
        </adam-header-bar>
        <app-drawer
            ref="appDrawer"
            :data.prop="applications"
            :labels.prop="getAppDrawerLabels"
            :on-click.prop="handleAppClick"
        >
            <span
                slot="appLogo"
                class="app-logo"
            >
                <img src="~adam.ui-core/assets/brand/logo-coode-rgb.svg">
            </span>
        </app-drawer>
        <snackbar />
    </div>
</template>

<script lang="ts">

import { Component, Mixins, Prop } from 'vue-property-decorator';
import UserProfile from './user-profile/user-profile.vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { AppService, NotificationService } from '@/services';
import { Application, Profile} from '@/models';
import Snackbar from '@/components/snackbar/snackbar.vue';
import clickOutside from '@/directives/click-outside';
import { mapState } from 'vuex';
import { EventBus, LANGUAGES,LANGUAGES_N,LocalStorageHelper,  reloadCurrentPage } from '@/utils';
import _ from 'lodash';
import ComponentSecurity from '@/mixins/component-security';
import { Action, Notification } from '@/store/modules/contracts/Notification';
import { selectedLanguageToMomentLocale, differenceTwoDates } from '@/utils/date-time-utils';
import moment from 'moment';
import humanizeDuration from 'humanize-duration';
import SearchTerm from '@/components/filters/search-term.vue';
import { sdk } from '@/utils/fe-sdk/sdk';

@Component({
    name: 'header-wrapper',
    directives: {
        clickOutside,
    },
    components: {
        userProfile: UserProfile,
        snackbar: Snackbar,
        SearchTerm
    },
    computed: {
        ...mapState('user', ['profile']),
    },
})
export default class HeaderWrapper extends Mixins(ComponentSecurity) {
    @Prop({ required: true })
    private applications!: MenuEntry[];

    private notificationService: NotificationService = new NotificationService();
    private scope: string | undefined  = process.env.VUE_APP_LFE_SCOPE;
    private user = {
        name: '',
        email: '',
    };
    private profile!: Profile;
    private selectedLanguage = LocalStorageHelper.getSelectedLanguage();
    private changeSelectedLanguage(): void {
        LocalStorageHelper.setSelectedLanguage(this.selectedLanguage);
        this.$i18n.locale = this.isTestEnvironment ?
            LANGUAGES_N[this.selectedLanguage ?? 0] : LANGUAGES[this.selectedLanguage ?? 0];
        reloadCurrentPage();
    }

    private get languageOptions(): { label: string; value: string }[] {
        return this.$i18n.availableLocales.map((lang) => {
            return {
                label: `${this.$t(`languages.${lang}`)}` as string,
                value: lang,
            };
        });
    }

    private async mounted(): Promise<void> {
        await this.init();
    }

    private async init(): Promise<void> {
        await this.setProfileData();
    }

    private async setProfileData(): Promise<void> {
        const profile: any = sdk?.localStorage.getPermissionsLocalStorage();
        if (profile) {
            this.user.name = profile.name;
            this.user.email = profile.email;
            this.$store.commit('user/SET_PROFILE', {
                email: profile.email,
                'family_name': profile.name.split(', ')[0],
                'given_name': profile.name.split(', ')[1],
                userKid: profile.email.split('@')[0],
            });
        }
    }

    private environment = process.env.VUE_APP_ENVIRONMENT;

    private get isProductionEnv(): boolean {
        return this.environment === 'prd';
    }

    private get isUatEnv(): boolean {
        return this.environment === 'uat';
    }

    private get isDevelopmentEnv(): boolean {
        return ['dev', 'development'].includes(this.environment ?? '');
    }

    private get isOnOverviewPage(): boolean {
        return this.$route.name === 'Home';
    }

    private clickDrawer(): void {
        (this.$refs.appDrawer as any).open = true;
    }

    private changeLanguage(localeCode: string): void {
        sdk?.translation.uiTranslation.changeCurrentLanguage(localeCode);
        window.location.reload();
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }

    get getAppDrawerLabels(): any {
        return { applications: this.$i18n.t('appsWithAccess'), miscellaneous: this.$i18n.t('appsWithoutAccess') };
    }

    private translateDateTimeInDescription(input: string, selectedLanguage: typeof LANGUAGES): string {
        const notificationTimeZone = 'Europe/Berlin';
        const userTimeZone = moment.tz.guess();
        const regex = /(\d{4}-\d{2}-\d{2})[A-Z]+(\d{2}:\d{2}:\d{2}).([0-9+-:]+)/gm;
        const durationRegex = /[(].*[)]h/gm;
        const dateMatch = input.match(regex);
        const durationMatch = input.match(durationRegex);
        let replacedString = _.cloneDeep(input);

        const localeKey = selectedLanguageToMomentLocale(selectedLanguage);

        if(dateMatch) {
            for (const iDate of dateMatch) {
                const date = moment.tz(iDate.slice(0, 16), notificationTimeZone).tz(userTimeZone).locale(localeKey);
                replacedString = replacedString.replace(iDate, date.format('LLLL'));
            }
        }

        if (dateMatch && durationMatch && durationMatch.length >= 1 && dateMatch.length === 2) {
            const duration = moment
                .duration(moment.tz(dateMatch[1].slice(0, 16), notificationTimeZone).
                    diff(moment.tz(dateMatch[0].slice(0, 16), notificationTimeZone), 'seconds'), 'seconds')
                .locale(localeKey);

            const replacementString = humanizeDuration(
                duration.asMilliseconds(),
                {
                    language: (localeKey as string).split('-')[0],
                    fallbacks: ['en']
                })

            replacedString = replacedString.replace(durationMatch[0], replacementString);
        }

        return replacedString;
    }

    private getTranslatedTitleAndDescription(notification: Notification,
        selectedLanguage: string): { title: string; description: string } {
        const translation = notification.translations &&
        notification.translations[this.isTestEnvironment ? LANGUAGES_N[selectedLanguage] : LANGUAGES[selectedLanguage]]
            ? notification.translations[this.isTestEnvironment ? LANGUAGES_N[selectedLanguage] :
                LANGUAGES[selectedLanguage]]
            : null;
        const translatedTitle = translation?.title ?? notification.title;
        const translatedDescription = translation?.description ?? notification.description;

        return {
            title: translatedTitle,
            description: this.translateDateTimeInDescription(translatedDescription,
                this.isTestEnvironment ? LANGUAGES_N[selectedLanguage] : LANGUAGES[selectedLanguage]),
        }
    }

    get showNotifications(): boolean {
        return this.$store.getters['notifications/showNotifications'];
    }

    private async getNotifications(): Promise<void> {
        this.$store.commit('loading');

        try {
            const notifications = (await this.notificationService.getAllNotifications(
                {
                    ListNotifications: true,
                    ListTasks: true,
                    GetRead: false,
                    page: 1,
                    size: 300,
                    term: '',
                    useCaseIds: [Number(process.env.VUE_APP_USE_CASE_ID)]
                })).result.items;

            this.$store.commit('notifications/notifications', notifications);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'notificationsNotLoaded');
        } finally {
            this.$store.commit('loading');
        }
    }
    
    private getdocumentnumber(input: string): string{
        const a = input.match(/'([^']+)'/);
        if(a){
            return a[1].replaceAll('/','-');
        }
        else
            return '';
    }
    get notifications(): any[] {

        const notis: any[] = [];

        const selectedLanguage = LocalStorageHelper.getSelectedLanguage() ?? 'EN';

        (this.$store.getters['notifications/notifications'] ?? []).forEach((n: Notification) => {
            const appUri = process.env.VUE_APP_URL;
            const { title: translatedTitle, description: translatedDescription } =
                this.getTranslatedTitleAndDescription(n, selectedLanguage);

            const docnum = this.getdocumentnumber(translatedDescription);
            if (n.type === 'Notification') {
                const diff = differenceTwoDates(n.timestamp, new Date(),
                    {disableMinutes: false, disableHours: false})
                notis.push({
                    id: n.id,
                    isRead: n.isRead,
                    title: translatedTitle,
                    description: translatedDescription,
                    created: diff,
                    type: 0,
                    useCaseName: this.$t('notificationHeaderContextMenu.learnings'),
                    redirectUrl:`${appUri}/document/${docnum}`,

                });
            }
        });

        return notis;
    }
    private handleRedirect(event: any): void {
        this.$store.dispatch(
            'notifications/markNotificationAsRead',
            this.notifications.find((n: Notification) => n.id === event.detail.id));

        this.notificationService.markNotificationAsRead(event.detail.id);

        window.open(event.detail.redirectUrl);
    }
    private handleApprove(event: any): void {
        const noti = this.notifications.find((n: Notification) => n.id === event.detail.id);
        this.handleAction(noti, 0);
    }
    private handleReject(event: any): void {
        const noti = this.notifications.find((n: Notification) => n.id === event.detail.id);
        this.handleAction(noti, 1);
    }
    private redirectToAppStore (): void {
        window.open(`${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/notifications-overview`);
    }
    private goToSettings (): void {
        this.$router.push({name: 'Settings'})
    }
    get isTestEnvironment(): boolean {
        return !this.$store.getters['isProductionEnv'];
    }
    private handleAction(notification: any, actionId: number): void {
        if (notification.actions && actionId === 0) {
            const foundCorrespondingAction = notification.actions.find((action: Action) => action.id === actionId);

            if (foundCorrespondingAction.includeColumnIdsInRequest) {
                const content = {} as any;

                foundCorrespondingAction.includeColumnIdsInRequest.forEach((columnId: number) => {
                    const foundDynamicColumn = notification.dynamicColumns.find(
                        (column: any) => column.id === columnId);
                    content[columnId.toString()] = foundDynamicColumn.value
                })

                this.notificationService.doAction(notification.workflowInstanceId, actionId, content);
                this.$store.dispatch('notifications/removeNotification', notification);
                return;
            }
        }
        this.notificationService.doAction(notification.workflowInstanceId, actionId);
        this.$store.dispatch('notifications/removeNotification', notification);
    }

    private async clearAllNotifications(): Promise<void> {
        await this.notificationService.clearAllNotifications();
        await this.getNotifications();
    }

}
</script>

<style scoped lang="less">
@import '../../variables.less';

.notification-header {
    margin: 1.5rem 0;
    &__context-menu {
        &__item {
            width: 28rem;
            display: flex;
            flex-direction: row-reverse;
            &--icon {
                width: fit-content;
                padding-right: 0;
            }
        }
    }
}

::v-deep .adam-header-notifications__notification__description {
    white-space: pre-line;
}
/deep/ .adam-header-notifications__notification__actions__redirect__button {
    padding-left: 0;
}

/deep/ .adam-header-notifications {
    .adam-header-notifications__icon--focused.adam-header-bar {
        background: none;
        --stroke: white;
    }

    .adam-header-notifications__icon.adam-header-bar:hover {
        background: none;
        --stroke: white;
    }
}

/deep/ .adam-header-user {
    .adam-header-user__icon.adam-header-bar:hover {
        background: none;
        --stroke: white;
    }

    .adam-header-user__icon--focused.adam-header-bar {
        background: none;
        --stroke: white;
    }
}

::v-deep .adam-header__separator.adam-header-bar {
    background: white;
}
/deep/ .adam-header-notifications__notification__meta {
    margin-bottom: 1rem;
}

/deep/ .adam-header-notifications__dropdown.adam-header-bar {
    max-height: 90vh;
}
.app-logo {
    img {
        height: 90%;
        margin-right: 1rem;
        padding: 6px 6px 3px;
    }

    .app-logo-img{
        height: 4.5rem;
    }
}

::v-deep .pui-global-header__app {
    &:before {
        content: '';
        background-image: url(~adam.ui-core/assets/brand/logo-coode-white.svg);
        background-repeat: no-repeat;
        background-size: contain;
        height: 48px;
        width: 150px;
        margin-right: 1rem;
    }
}

/deep/ .create-button{
    padding-left:88%;
}

.search-bar {
    height: 3.6rem;
    border: none;
    width: 45rem;

    /deep/ .pui-form-input-field__input {
        color: @white;

        &::placeholder {
            color: @white;
            opacity: 1;
        }
    }

    /deep/ svg {
        color: @white;
    }
}
/deep/ .language-selector {
    width: 200px;
    margin-right: 1rem;

    input {
        height: 3.6rem;
    }

    .pui-form-type-ahead-input-single-select {
        min-height: unset;
    }

    .pui-form-type-ahead-input-single-select--active {
        border-color: @warm-grey;
    }

    .pui-form-type-ahead-input-single-select:hover {
        border-color: @warm-grey;
    }
}
.title-class{
    font-size: 2rem;
    color: white;
}
.languages {
    display: flex;
    justify-content: flex-end;

    &__selector {
        min-width: 200px;
    }
}
::v-deep .adam-header-notifications__notification__description.adam-header-bar{
    word-wrap: break-word;
    font-size: 1.4rem;
    line-height: 1.8rem;
    padding: .1rem .2rem;
}

::v-deep .bg-uat {

    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-uat;

        .adam-header-user__icon.adam-header-bar {
            path {
                fill:@uniper-uat;
            }
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-uat-darker;
        }

        .adam-header-notifications__icon.adam-header-bar:hover,
        .adam-header-notifications__icon--focused.adam-header-bar {
            background: @uniper-uat-darker;
        }

        .adam-header__logo.adam-header-bar {
            &:hover {
                --fill: @uniper-uat-darker;
            }
        }

        .search-bar {
            background-color: @uniper-uat-darker;
            .pui-form-input-field__input {
                background-color: @uniper-uat-darker;
            }
        }
    }
}
::v-deep .bg-dev {

    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-dev;

        .adam-header-user__icon.adam-header-bar {
            path {
                fill: @uniper-dev;
            }
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-dev-darker;
        }

        .adam-header-notifications__icon.adam-header-bar:hover,
        .adam-header-notifications__icon--focused.adam-header-bar {
            background: @uniper-dev-darker;
        }

        .adam-header__logo.adam-header-bar {
            &:hover {
                --fill: @uniper-dev-darker;
            }
        }

        .search-bar {
            background-color: @uniper-dev-darker;
            .pui-form-input-field__input {
                background-color: @uniper-dev-darker;
            }
        }
    }
}
::v-deep .bg-prd {

    .adam-header.adam-header-bar, adam-header-bar {
        background-color: @uniper-blue;

        .adam-icon.circle.fill .wrapper.adam-icon {
            border-radius: 0%;
        }

        .adam-header-user__icon.adam-header-bar {
            path {
                fill: @uniper-blue;
            }
        }

        .adam-header-user__icon.adam-header-bar:hover,
        .adam-header-user__icon--focused.adam-header-bar {
            background: @uniper-prod-darker;
        }

        .adam-header-notifications__icon.adam-header-bar:hover,
        .adam-header-notifications__icon--focused.adam-header-bar {
            background: @uniper-prod-darker;
        }

        .adam-header__logo.adam-header-bar {
            &:hover {
                --fill: @uniper-prod-darker;
            }
        }

        .search-bar {
            background-color: @uniper-prod-darker;
            .pui-form-input-field__input {
                background-color: @uniper-prod-darker;
            }
        }
    }
}
</style>
