<template>
    <div class="mt-1">
        <div class="flex-container justify-between filters-container">
            <div class="flex-container flex-center date-range">
                <date-range
                    :key="this.$route.fullPath"
                    class="mr-2"
                />
                <top-filters />
            </div>
            <pui-button
                data-cy="openCreatorMode"
                :disabled="$route.name === 'DocumentAdd'"
                class="new-learning-button"
                @click="$route.name !== 'DocumentAdd' && $router.push({ name: 'DocumentAdd' })"
            >
                {{ $t('actions.newLearning') }}
            </pui-button>
        </div>
        <inline-notification
            v-if="showNoificationInfoBanner"
            class="mt-2"
            :title="$t('actions.alertMe')"
            icon="mail"
            icon-color="#1FBAFF"
            @close="closeNotification()"
        >
            <div>
                <span>{{ $t('filters.alertMeNotification') }}
                </span>
                <pui-link
                    :title="$t('settings')"
                    href="/settings"
                />
                <span>{{ ` ${$t('filters.page')}.` }}</span>
            </div>
        </inline-notification>
        <inline-notification
            v-if="showFavoritesNotification"
            class="mt-2 favorite-notification"
            icon="star-active"
            icon-color="#FFA500"
            @close="closeFavoritesNotification()"
        >
            <div>
                <span>{{ ` ${$t('filters.favoriteFiltersNotification')} ` }}</span>
                <pui-link
                    :title="$t('actions.viewAllLearnings')"
                    @click="handleClearAllAppliedFilters"
                />
            </div>
        </inline-notification>
        <documents-list class="mt-2" />
    </div>
</template>

<script lang="ts">

import { Component, Vue, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import DocumentsList from '@/components/document/documents-list.vue';
import TopFilters from '@/components/filters/top-filters.vue';
import DateRange from '@/components/filters/date-range.vue';
import InlineNotification from '@/components/inline-notification/inline-notification.vue';
import { EventBus } from '@/utils';
import { FiltersService } from '@/services/filters-service';
import { capitalizeFilters, initializeFilters, numberOfAppliedFilters } from '@/utils/filters-helper';
import { getPersistedFavoriteFilters } from '@/utils/helpers/filters-persistence';
import { GetDocumentsQuery } from '@/models';

@Component({
    name: 'home',
    components: { DocumentsList, TopFilters, DateRange, InlineNotification },
})
export default class Home extends Vue {

    private showNoificationInfoBanner = false;
    private showFavoritesNotification = false;
    private filtersService = new FiltersService();

    beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
        if (!to.query.page || !to.query.Sort) {
            next(vm => {
                vm.$router.replace({ query: { page: '1', Sort: 'Date' } });
            });
        }
        next();
    }

    private async mounted(): Promise<void> {
        EventBus.$on(EventBus.GLOBAL.SCROLL_TOP, this.scrollPage);
        this.loadPreSavedFilters();
        this.showFavoritesNotification = numberOfAppliedFilters(this.favoritesFilters) > 0;
    }

    private async loadPreSavedFilters(): Promise<void> {
        const { result } = await this.filtersService.getFilters();

        if (JSON.stringify(result) === '{}') {
            this.showNoificationInfoBanner = true;
        } else {
            const filters = initializeFilters(capitalizeFilters((result)));
            await this.$store.dispatch('filters/UPDATE_FILTERS_LOCALLY', filters);
        }
    }

    scrollPage(): void{
        document.getElementsByClassName('pui-application-layout__main')[0].scrollTo(0,0)
        // this.$el.scrollTo(0,0); implement this..instead the above line.
    }

    private beforeDestroy(): void {
        EventBus.$off(EventBus.GLOBAL.SCROLL_TOP);
    }

    private closeNotification(): void {
        this.showNoificationInfoBanner = false;
    }

    private closeFavoritesNotification(): void {
        this.showFavoritesNotification = false;
    }

    private handleClearAllAppliedFilters(): void {
        this.$router.push({ query: { page: '1', Sort: 'Date' } });
        this.showFavoritesNotification = false;
    }

    private get favoritesFilters(): GetDocumentsQuery {
        return initializeFilters(getPersistedFavoriteFilters());
    }

    @Watch('$route.query')
    onRouteChanged(query: any): void {
        const currentFilters = initializeFilters(query);
        const queryIsFavoriteQuery = JSON.stringify(currentFilters) === JSON.stringify(this.favoritesFilters);
        this.showFavoritesNotification = numberOfAppliedFilters(query) > 0 && queryIsFavoriteQuery;
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';
.filters-container{
    position: sticky;
    top: 0;
    background-color: @white;
    z-index: 3;
    padding: 1.5rem;
}
.new-learning-button {
    height: 100%;
    align-self: center;
}
/deep/ .favorite-notification {
    background-color: #fff6e5;
    border: 1px solid #ffa500;
    .banner {
        align-items: center;

        .content {
            padding-top: 0;

            &__description {
                margin-top: 0;
            }
        }
    }
}
</style>
