import moment from 'moment-timezone';
import { LANGUAGES, LANGUAGES_N } from '@/utils/languages';
import { formatDateTime } from './helpers/formatters';

/**
 * Helper function to convert the given date into a string representation of local time.
 * @param date
 * @returns String with format: YYYY-MM-DDThh:mm:ss+hh:mm
 *
 * FIXME Use moment.js to solve this more elegantly.
 */
export function toLocalizedIsoString (date: Date): string {
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num: number): string {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

export type envname = typeof LANGUAGES | typeof LANGUAGES_N

export function parseDateForBackend (date: Date): string {
    const timestamp = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return timestamp.toISOString()
}

export function parseLocalDateForBackend(date: Date): string {
    const timestamp = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
    return toLocalizedIsoString(timestamp)
}

export function parseBackendUtcTimeAsLocal(timestamp: string): Date {
    if (timestamp.charAt(timestamp.length - 1) === 'Z') {
        timestamp = timestamp.substring(0, timestamp.length - 1)
    }

    timestamp = timestamp.split('.')[0];

    const date = new Date(timestamp + '.000Z')
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
}

// Only locale-respecting formats are supported, see moment.js docs
// The timezone param, is telling moment.js what timezone the passed date is.
// Default is UTC.
export function toDisplayDateString(date: string, locale = 'en', format = 'DD.MM.YYYY HH:mm'): string {
    const parsedDate = parseBackendUtcTimeAsLocal(date);
    return moment(parsedDate.toISOString())
        .add(parsedDate.getTimezoneOffset(), 'minutes')
        .locale(locale)
        .format(format)
        .toString();
}

// Calculates the difference between 'now' and the provided date in the form of an object that contains
// the diff and the unit as a translation key to be used later like '5 days ago', '2 hours ago', etc.
// while seconds is the last supported measurement. This is also a sane limitation by moments.js.
// By default moment truncates decimal places, so we will allways check for 0 which is falsy in js
// This will mainly be used by the notifications
/* eslint-disable sonarjs/cognitive-complexity */
export function differenceTwoDates(startDate?: Date | string, endDate?: Date | string, options?:
     { disableHours: boolean; disableMinutes: boolean }): string {
    if (startDate && endDate) {
        const leftDate = new Date(endDate);
        const rightDate = new Date(startDate);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        let diffInMilliSeconds = Math.abs(rightDate - leftDate) / 1000;
        // calculate seconds
        const seconds = diffInMilliSeconds*1000;
        // calculate days
        const days = Math.floor(diffInMilliSeconds / 86400);
        diffInMilliSeconds -= days * 86400;

        // calculate hours
        const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
        diffInMilliSeconds -= hours * 3600;

        // calculate minutes
        const minutes = Math.floor(diffInMilliSeconds / 60) % 60;



        let difference = '';
        if (days > 0) {
            return formatDateTime(startDate as string);
        }
        if (hours > 0 && (!options?.disableHours ?? true)) {
            difference += `${hours} hrs `;
        }
        if (minutes > 0 && (!options?.disableMinutes ?? true)) {
            difference += `${minutes} min `;
        }
        if(seconds<=60){
            difference += `${seconds} sec`;
        }
        return difference;
    }
    return '';
}
export function toDisplayDateDifference(date: Date | string, locale = 'en'): { diff: number; unit: string } {
    const daysDiff = moment(date).locale(locale).diff(moment.now(), 'days');

    if(!daysDiff) {
        const hoursDiff = moment(date).locale(locale).diff(moment.now(), 'hours');

        if (!hoursDiff) {
            const minutesDiff = moment(date).locale(locale).diff(moment.now(), 'minutes');

            if (!minutesDiff) {
                const secondsDiff = moment(date).locale(locale).diff(moment.now(), 'seconds');

                return { diff: secondsDiff, unit: secondsDiff * -1 === 1 ?
                    'notificationTimes.sec' : 'notificationTimes.secs' };
            }

            return { diff: minutesDiff, unit: minutesDiff * -1 === 1 ?
                'notificationTimes.min' : 'notificationTimes.mins' };
        }
        return { diff: hoursDiff, unit: hoursDiff * -1 === 1 ? 'notificationTimes.hour' : 'notificationTimes.hours' };
    }
    return { diff: daysDiff, unit: daysDiff * -1 === 1 ? 'notificationTimes.day' : 'notificationTimes.days' };
}

// A call of format() without a param will use the local timezone.
// moment.js can handle either Date, date strings or milliseconds
export function toLocalIsoString(date: Date | string | number): string {
    return moment(date).format();
}

export function toLocalIsoFormattedString(date: Date | string | number, showSeconds = false): string {
    const format = showSeconds ? 'DD.MM.YYYY HH:mm:ss' : 'DD.MM.YYYY HH:mm';
    return moment(date).format(format);
}

/**
 * Helper function to get the unix timestamp in the timezone of the moment object.
 * @param date
 */
export function getTime(date: moment.Moment): number {
    const offset = date.utcOffset() * 60 * 1000;
    return date.valueOf() + offset;
}

export function translatedDateString(time: moment.Moment, selectedLanguage: string, format = 'LLLL'): string {
    let localeKey = 'en';
    if (selectedLanguage === LANGUAGES.DE && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES.NL && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES.HU && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES.EN && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'en';
    } else if (selectedLanguage === LANGUAGES_N.DE && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES_N.NL && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES_N.HU && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES_N.EN && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'en';
    }
    return time.clone().locale(localeKey).format(format);
}

/**
 * Helper function to round/floor/ceil a moment.Moment object
 * @param date
 * @param duration
 * @param method
 */
export function roundDate(date: moment.Moment,
    duration: moment.Duration, method: 'round' | 'floor' | 'ceil'): moment.Moment {
    const internal = date.clone();
    const tz = date.tz();
    if (tz) {
        return moment(Math[method]((+internal) / (+duration)) * (+duration)).tz(tz);
    }
    return moment(Math[method]((+internal) / (+duration)) * (+duration));
}

export const supportedTimes = ['year', 'years', 'day', 'days', 'hours', 'hours', 'min', 'mins', 'sec', 'secs'];


/**
 * RegExp to test a string for a full ISO 8601 Date
 * Does not do any sort of date validation, only checks if the string is according to the ISO 8601 spec.
 *  YYYY-MM-DDThh:mm:ss
 *  YYYY-MM-DDThh:mm:ssTZD
 *  YYYY-MM-DDThh:mm:ss.sTZD
 * @see: https://www.w3.org/TR/NOTE-datetime
 * @type {RegExp}
 */
const ISO_8601_FULL = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;

// using moment(dateCandidate).isValid() is not reliable enough: ('hallo').isValid() == true
export const isValidDate = (dateCandidate: string): boolean => ISO_8601_FULL.test(dateCandidate);

export const getFormattedDateIfValid = (dateCandidate: string): string => {
    return isValidDate(dateCandidate) ? toLocalIsoFormattedString(dateCandidate, true) : dateCandidate;
}

export const selectedLanguageToMomentLocale = (selectedLanguage: envname): moment.LocaleSpecifier => {
    let localeKey = 'en-gb';

    if (selectedLanguage === LANGUAGES.DE && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES.NL && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES.HU && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES.EN && process.env.VUE_APP_ENVIRONMENT == 'prd') {
        localeKey = 'en-gb';
    } else if (selectedLanguage === LANGUAGES_N.DE && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'de';
    } else if (selectedLanguage === LANGUAGES_N.NL && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'nl';
    } else if (selectedLanguage === LANGUAGES_N.HU && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'hu';
    } else if (selectedLanguage === LANGUAGES_N.EN && process.env.VUE_APP_ENVIRONMENT != 'prd') {
        localeKey = 'en-gb';
    } else {
        throw 'Unknown language!'
    }
    return localeKey;
}
