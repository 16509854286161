import { Category, IdToItemMap } from '@/models';

export const buildMapIdToObject = (map: IdToItemMap<Category>, next: Category): IdToItemMap<Category> => {
    if (next.id && !map[next.id]) {
        map[next.id] = next;
    }
    if (next.children.length) {
        next.children.reduce(buildMapIdToObject, map);
    }
    return map;
}

export const getNames = (category: Category): string => {
    const names = []
    names.push(category.name);
    if (category.children.length) {
        const childrenNames = category.children
            .map(getNames)
            .join(', ');
        names.push(childrenNames);
    }
    return names.join('/');
};
