export const SeverityAndRisk: {[key: string]: number} = {
    NotApplicable: -1,
    Low : 1,
    Medium : 2,
    Severe : 3,
}

export const ReviewStatus: {[key: string]: number} = {
    Draft: 1,
    InProcess: 2,
    Verified: 3,
    Published: 4,
    NotRelevant: 5,
    AutoApproved: 6,
}

export interface Filters {
    Severity?: number[];
    Risk?: number[];
    Hazard?: number[];
    Location?: string[];
    Consequence?: number[];
    Category?: number[];
    Fleet?: number[];
    Editor?: string[];
    ReviewStatus?: number[];
}
