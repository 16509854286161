<template>
    <div class="flex-col profile-container">
        <div class="profile-details">
            <template v-if="!isProdEnv">
                <div class="pb-1">
                    <button
                        type="button"
                        @click="copyToken()"
                        class="copy-token-button left-align"
                    >
                        {{ $t('copyToken') }}
                        <font-awesome-icon icon="copy" />
                    </button>
                </div>
                <div class="pb-1">
                    <a
                        :href="swaggerURL"
                        target="_blank"
                        class="swagger-link"
                    >
                        {{ $t('openSwagger') }}
                    </a>
                </div>
                <div class="flex-col flex-3 pb-1">
                    <div class="version">
                        {{ $t('version') }}&nbsp;{{ version }}
                    </div>
                    <div class="version ">
                        {{ $t('apiUrl') }}&nbsp;
                        <a
                            :href="apiUrl"
                            class="swagger-link"
                        >
                            {{ apiUrl }}
                        </a>
                    </div>
                </div>
            </template>
            <pui-button
                state="secondary"
                icon="report"
                class="profile-action-button"
                @click="sendFeedback()"
            >
                {{ $t('sendFeedback') }}
            </pui-button>
            <pui-button
                state="secondary"
                icon="warning"
                class="profile-action-button profile-action-button--important"
                @click="reportIncident()"
            >
                {{ $t('reportIncident') }}
            </pui-button>
            <pui-button
                icon="power"
                class="profile-action-button"
                @click="logOut()"
            >
                {{ $t('signOut') }}
            </pui-button>
        </div>
        <input
            type="text"
            class="copy-text"
            id="copyText"
        >
    </div>
</template>

<script lang="ts">

import { sdk } from '@/utils/fe-sdk/sdk';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'user-profile',
})
export default class UserProfile extends Vue {
    @Prop({ default: false })
    private isProdEnv!: boolean;

    private $pui!: { toast: Function };
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private detailsToggled = false;
    private appStoreUrl: string | undefined = process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL;
    private useCaseId: string | undefined = process.env.VUE_APP_USE_CASE_ID;

    private async logOut(): Promise<void> {
        sdk?.auth.logout();
    }

    private sendFeedback(): void {
        window.open(`${this.appStoreUrl}/contact-page/${this.useCaseId}?isFeedback=true`, '_blank');
    }

    private reportIncident(): void {
        window.open(`${this.appStoreUrl}/incident-page/${this.useCaseId}`, '_blank');
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await sdk?.auth.getToken(process.env.VUE_APP_LFE_SCOPE ?? '');
            this.copyToClipboard(`Bearer ${token}`);
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    private copyToClipboard(value: string): void {
        const textElement: any = document.getElementById('copyText');
        textElement.value = '';
        textElement.value = value;
        textElement.select();
        document.execCommand('copy');
        this.$pui.toast({
            type: 'info',
            title: this.$t('copiedToClipboard'),
            copy: '',
        });
    }

    private offFocus(): void {
        if (this.detailsToggled) {
            this.detailsToggled = false;
        }
    }
}
</script>

<style scoped lang="less">
@import '../../../variables.less';

.profile-details {
    color: @dark-blue-grey;
    z-index: 10;
    font-size: 1.6rem;
    right: 0;

    .profile-action-button {
    text-transform: none;
    width: 100%;
    border-radius: 0;
    margin-bottom: 2px;
    height: 3.6rem;

    &--important {
        border: 1px solid @contextual-red !important;
        color: @contextual-red !important;
        &:hover {
            color: @dark-red !important;
            border: 1px solid @dark-red !important;
        }
    }
}

    .copy-token-button {
        width: 100%;
        color: @dark-blue-grey;
        transition: 0.2s ease-in;
        box-shadow: none;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;

        :hover {
            transition: 0.2s ease-in;
        }
    }

    .swagger-link {
        color: @uniper-blue;
    }
}

.version {
    font-size: 1.3rem;
    margin: 0.3rem;
}

.copy-text {
    position: fixed;
    top: -99999rem;
}
</style>
