<template>
    <div
        class="snackbar"
        :class="{'show': isVisible, 'error': isErrorState, 'success': isSuccessState}"
    >
        <span v-if="message !== ''">{{ $t(message) }}</span>
        <button
            type="button"
            @click="dismiss"
        >
            {{ $t('dismiss') }}
        </button>
    </div>
</template>

<script lang="ts">

import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import './snackbar.less';

const DEFAULT_DURATION = 5000;

interface Options {
    duration: number | null;
    state: 'error' | 'success';
}

@Component({
    name: 'snackbar',
})
export default class Snackbar extends Vue {
    private message = '';
    private duration = DEFAULT_DURATION;
    private isVisible = false;
    private timeout: any;
    private state = '';

    get isErrorState(): boolean {
        return this.state === 'error';
    }
    get isSuccessState(): boolean {
        return this.state === 'success';
    }

    private created(): void {
        const showSnackbar = (message: string, options: Options): void => {
            if (options) {
                if (options.duration) {
                    this.duration = options.duration;
                }
                if (options.state) {
                    this.state = options.state;
                }
            }
            this.message = message;
            this.isVisible = true;
            this.timeout = setTimeout(() => {
                this.isVisible = false;
                this.message = '';
                this.duration = DEFAULT_DURATION;
                this.state = '';
            }, this.duration);
        }
        EventBus.$on(EventBus.GLOBAL.SHOW_SNACKBAR, showSnackbar);
        this.$once('hook:beforeDestroy', (): void => {
            EventBus.$off(EventBus.GLOBAL.SHOW_SNACKBAR, showSnackbar);
            this.dismiss();
        });
    }

    private dismiss(): void {
        this.isVisible = false;
        clearTimeout(this.timeout);
    }
}

</script>

<style lang='less' scoped>
@import '../../variables.less';

.snackbar {
    position: fixed;
    left: 1%;
    border: 1px solid @dark-grey;
    border-radius: 5px;
    background: @dark-grey;
    color: @white;
    font-family: Roboto, sans-serif;
    font-size: 1.2rem;
    padding: 1rem;
    box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.2);
    bottom: -100px;
    transition: all 0.2s ease-in;
    z-index: 15;
    &.show {
        transition: all 0.2s ease-in;
        bottom: 2%;
    }
    button {
        color: @medium-violet;
        background: transparent;
        border: none;
        outline: none;
        font-size: 1.6rem;
        margin-left: 1rem;
        text-transform: uppercase;
        box-shadow: none;
        &:focus {
            outline: none;
        }
        &:hover {
            cursor: pointer;
        }
    }
    &.error {
        background-color: @red-darker;
        border-color: @red-darker;
    }
    &.success {
        background-color: @dark-green;
        border-color: @dark-green;
    }
    &.success,
    &.error {
        button {
            color: @white;
        }
    }
}
</style>
