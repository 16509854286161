import { ApiResponse, Profile } from '@/models';
import { sdk } from '@/utils/fe-sdk/sdk';

/**
 * @name UserService
 * @description This class is used to query API for data related to App needs,
 */
export class UserService {
    public async getAll(query = ''): Promise<ApiResponse<{ items: Profile[] }>> {
        return (await sdk?.core.userAccessManagement.request.api.get<ApiResponse<{ items: Profile[] }>>(
            `users/?Term=${query}`
        )).data;
    }
}
