<template>
    <pui-tree
        :has-search="showSearch"
        :use-fuzzy-search="showSearch"
        :tree-data="treeObjectItems"
        :multi-select="true"
        node-padding="s"
        @selected="handleItemSelected"
        @deselected="handleItemDeselected"
        :ref="hierarchyTreeRefName"
    >
        <template
            #nodeContent="node"
        >
            <div
                v-if="!hasCheckBoxesOnTheRight"
                class="ml-2"
            >
                <pui-form-checkbox
                    v-pui-tooltip="{message: showTooltipForTreeNodes ? node.title : ''}"
                    :checked="isNodeChecked(node)"
                    :label="node.title"
                    :value="node.title"
                    @click.native="(e) => e.preventDefault()"
                />
            </div>
            <div
                v-else
                class="flex-container flex-center justify-between node-content"
            >
                <span
                    class="node__title"
                    v-pui-tooltip="{message: showTooltipForTreeNodes ? node.title : ''}"
                >
                    {{ node.title }}
                </span>
                <div
                    class="flex-container"
                >
                    <pui-icon
                        v-if="isNodeFavorited(node)"
                        icon-name="star-active"
                        v-pui-tooltip="{ message: $t('filters.filterSavedAsFavorite')}"
                        icon-color="#0078DC"
                        class="mr-1"
                    />
                    <pui-form-checkbox
                        :checked="isNodeChecked(node)"
                        label=""
                        :value="node.title"
                        @click.native="(e) => e.preventDefault()"
                    />
                </div>
            </div>
        </template>
    </pui-tree>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Category } from '@/models';
import { TreeNode } from '@/models/tree';

@Component({
    name: 'multi-check-tree',
    components: {},
})
export default class MultiCheckTree extends Vue {
    @Prop({ type: String, required: true }) readonly filterProperty!: string;
    @Prop({ type: Array, required: true }) readonly options!: Category[];
    @Prop({ type: Boolean, default: false }) readonly showSearch!: boolean;
    @Prop({ type: Array, required: true }) readonly selected!: (string | number)[];
    @Prop({ type: Array, required: false, default: () => [] }) readonly favorited!: (string | number)[];
    @Prop({ type: Boolean, required: false, default: false }) readonly showTooltipForTreeNodes!: boolean;
    @Prop({ type: Boolean, default: true }) readonly hasCheckBoxesOnTheRight!: boolean;

    private readonly hierarchyTreeRefName = 'hierarchyTree';

    private mounted(): void {
        this.$nextTick(() => {
            if (this.$refs[this.hierarchyTreeRefName]) {
                this.markOptionsInTree();
            }
        });
    }

    // used for marking the elements selected as checked in the first tab (if any) in first render
    @Watch('selected')
    private onSelectedChange(): void {
        this.markOptionsInTree();
    }

    private markOptionsInTree(): void {
        (this.$refs[this.hierarchyTreeRefName] as any).selectedTreeNodeIds = [...this.selected].map(
            id => `${id}`
        );
    }

    private areAllChildrenSelected(parentNode: TreeNode | undefined, selectedIds: (number | string)[]): boolean {
        if (!parentNode) {
            return false;
        }

        const allChildrenIds = parentNode.nodes?.map(node =>
            this.getValueOfNodeId(node.id)
        );
        const formattedSelectedIds = [...selectedIds].map(id =>
            this.getValueOfNodeId(id)
        );
        return allChildrenIds?.every(id => formattedSelectedIds.includes(id));
    }

    private handleSelectionOfParent(parentId: string | number | undefined, listOfSelected: (number | string)[]): void {
        const nodeWithData = this.findNodeInTree({id: parentId});

        if (!nodeWithData?.parentId) {
            const allChildrenSelected = this.areAllChildrenSelected(nodeWithData, listOfSelected);
            if (allChildrenSelected && nodeWithData?.id) {
                const id = this.getValueOfNodeId(nodeWithData.id);
                if (!listOfSelected.includes(id)) {
                    listOfSelected.push(id);
                }
            }
            return;
        }

        const allChildrenSelected = this.areAllChildrenSelected(nodeWithData, listOfSelected);

        if (allChildrenSelected && parentId) {
            const id = this.getValueOfNodeId(parentId);
            listOfSelected.push(id);
            this.handleSelectionOfParent(nodeWithData?.parentId, listOfSelected);
        }
    }

    private getValueOfNodeId(id: string | number): string | number {
        return !isNaN(Number(id)) ? Number(id) : id;
    }

    private handleItemSelected(node: TreeNode): void {
        const id = this.getValueOfNodeId(node.id);

        const nodeWithData = this.findNodeInTree(node);
        const isDirectory = nodeWithData?.payload?.isDirectory;
        const allParentIds = this.findAllParentIdsOfNode(nodeWithData);

        const updatedArray = [...this.selected, id];

        let childrenIds = [];

        if (isDirectory) {
            const allNestedChildrenIds = this.findNestedChildrenIdsOfNode(nodeWithData);

            childrenIds = [allNestedChildrenIds.map((id) =>
                this.getValueOfNodeId(id)).filter((childId) =>
                !this.selected.includes(childId)
            )];

            const idsToBeSelected = [...this.selected, ...childrenIds].flat();
            allParentIds.forEach(parentId => this.handleSelectionOfParent(parentId, idsToBeSelected));

            this.$emit('update', {[this.filterProperty]: idsToBeSelected});
            return;
        }

        allParentIds.forEach(parentId => this.handleSelectionOfParent(parentId, updatedArray));
        this.$emit('update', {[this.filterProperty]: updatedArray});
    }

    private findNestedChildrenIdsOfNode(parentNode: TreeNode | any): (string | number)[] {
        if (!parentNode) {
            return [];
        }

        let childrenIds: (number | string)[] = [parentNode.id];

        if (parentNode.nodes) {
            for (const child of parentNode.nodes) {
                const childIds = this.findNestedChildrenIdsOfNode(child);
                childrenIds = childrenIds.concat(childIds);
            }
        }
        return childrenIds;
    }

    private findAllParentIdsOfNode(childNode?: TreeNode): any[] {
        if (!childNode) {
            return [];
        }

        let nodeWithData: TreeNode | undefined = childNode;
        const parentIds: (number | string)[] = [];

        while (nodeWithData && nodeWithData.parentId !== null) {
            parentIds.push(nodeWithData.parentId);
            nodeWithData = this.findNodeInTree({ id: nodeWithData.parentId });
        }

        return parentIds;
    }

    private findNodeInTree(
        node: TreeNode | Partial<TreeNode> | any,
        nodesToSearch: TreeNode[] = this.tree?.nodes
    ): TreeNode | undefined {
        for (const currentNode of nodesToSearch) {
            if (currentNode.id === node.id) {
                return currentNode;
            }

            if (currentNode.nodes) {
                const result = this.findNodeInTree(node, currentNode.nodes);
                if (result) {
                    return result;
                }
            }
        }

        return undefined;
    }

    private handleItemDeselected(node: TreeNode): void {
        const id = this.getValueOfNodeId(node.id);
        const nodeWithData = this.findNodeInTree(node);
        const isDirectory = nodeWithData?.payload?.isDirectory;
        const allParentIds = this.findAllParentIdsOfNode(nodeWithData).map((id) =>
            this.getValueOfNodeId(id)
        );

        const updatedArray = [...this.selected].filter(nodeId => nodeId != id && !allParentIds.includes(nodeId));

        if (isDirectory) {
            const allIdsToDeselect = [...this.findNestedChildrenIdsOfNode(nodeWithData), node.id].map((id) =>
                this.getValueOfNodeId(id)
            );
            const updatedList = this.selected.filter(item =>
                !allIdsToDeselect.includes(item) && !allParentIds.includes(item)
            );
            this.$emit('update', {[this.filterProperty]: updatedList});
            return;
        }

        this.$emit('update', {[this.filterProperty]: updatedArray});
    }

    private isNodeChecked(node: TreeNode): boolean {
        const selectedNodesList = (this.$refs[this.hierarchyTreeRefName] as any)?.selectedTreeNodeIds ?? [];
        return selectedNodesList.includes(node.id);
    }

    private isNodeFavorited(node: TreeNode): boolean {
        const id = this.getValueOfNodeId(node.id);
        return this.favorited.includes(id);
    }

    private mapTreeData(data: any, parentId: string | null = null): any {
        return data?.map((item: any): any => {
            return ({
                title: item?.name,
                id: `${item?.id}`,
                parentId,
                nodes: item?.children ? this.mapTreeData(item?.children, `${item?.id}`) : []
            })
        })
    }

    get treeObjectItems(): any {
        return {
            nodes: this.mapTreeData(this.options)
        }
    }

    get tree(): any {
        return (this.$refs[this.hierarchyTreeRefName] as any)?.tree;
    }
}
</script>

<style lang="less" scoped>
.pui-tree {
    /deep/ .pui-tree-node--selected>.pui-tree-node__content-wrapper {
        background-color: unset;
    }

    /deep/ .pui-tree-node--selected .pui-tree-node__content-wrapper:hover {
        background-color: rgba(0,120,220,0.15);
    }

    /deep/ .pui-tree__search {
        max-width: 100%;
        padding: 2rem;
        position: sticky;
        background-color: white;
        top: 0;
        z-index: 1;
        margin: 0;
    }

    /deep/ .pui-tree__search > .pui-tree__search-input {
        width: 50%;
    }

    .node__title {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 24rem;
    }
}

.pui-form-checkbox {
    margin-bottom: 0;
    font-size: 1.4rem;
}
</style>
