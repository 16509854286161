<template>
    <div v-if="filterPropertyQuery.length">
        <div class="mb-1">
            {{ header }}
        </div>
        <pui-chip
            v-for="filter in appliedIds"
            :key="filter[optionProperty]"
            :label="filter.name"
            removable
            @remove="remove(filter[optionProperty])"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Category } from '@/models';

@Component({
    name: 'applied-filters',
})
export default class AppliedFilters extends Vue {
    @Prop({ type: String, required: true }) readonly filterProperty!: string;
    @Prop({ type: String, default: 'id' }) readonly optionProperty!: keyof Category;
    @Prop({ type: Array, required: true }) readonly options!: Category[];
    @Prop({ type: String, required: true }) readonly header!: string;

    private get filterPropertyQuery(): string[] {
        const query = this.$route.query[this.filterProperty];
        if (query) {
            return Array.isArray(query) ? query as string[] : [query];
        } else {
            return [];
        }
    }

    private get appliedIds(): Category[] {
        if (this.options.length) {
            return this.options
                .filter((option) => this.filterPropertyQuery.includes(String(option[this.optionProperty])));
        } else {
            return [];
        }
    }

    private remove(id: string | number): void {
        const updated = this.filterPropertyQuery.filter((appliedId) => {
            const compare = Number.isInteger(id) ? Number(appliedId) : appliedId;
            return compare !== id;
        });
        if (updated.length) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: '1',
                    [this.filterProperty]: updated,
                },
            });
        } else {
            const query = { ...this.$route.query };
            delete query[this.filterProperty];
            this.$router.push({ query });
        }
    }
}
</script>
