import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Category, IdToItemMap, TranslationParams } from '@/models';
import { ApplicationState } from '@/store';
import { FleetService } from '@/services/fleet-service';
import { buildMapIdToObject } from '@/utils/helpers/mappers';
import { getRootTranslationKey, getTranslationForCategory } from '@/utils/translation-helper';

const fleetService: FleetService = new FleetService();

interface FleetState {
    fleets: Category[];
}

const state: FleetState = {
    fleets: [],
};

const getters: GetterTree<FleetState, ApplicationState> = {
    FLEETS_BY_ID(state: FleetState) {
        const copy: Category[] = JSON.parse(JSON.stringify(state.fleets));
        return copy.reduce(buildMapIdToObject, {} as IdToItemMap<Category>);
    },
};

const mutations: MutationTree<FleetState> = {
    SET_FLEETS(state: FleetState, payload: typeof state.fleets) {
        state.fleets = payload;
    }
};

const actions: ActionTree<FleetState, ApplicationState> = {
    async GET_FLEETS(
        { commit }: ActionContext<FleetState, ApplicationState>,
        params: TranslationParams,
    ): Promise<void> {
        const { result } = await fleetService.getAll();
        commit('SET_FLEETS', result.map(fleet => getTranslationForCategory({
            category: fleet,
            rootTranslationKey: getRootTranslationKey('fleets'),
            selectedLanguage: params.selectedLanguage,
            translations: params.translations,
        })));
    },
};

const namespaced = true;

export const fleet: Module<FleetState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
