import BaseService from '@/utils/base-service';
import { ApiResponse, LikePayload } from '@/models';

/**
 * @name LikeService
 * @description This class is used to query API for data related to App needs,
 */
export class LikeService extends BaseService {
    constructor() {
        super('like');
    }

    public async setLike(payload: LikePayload): Promise<ApiResponse<any>> {
        return (await this.api.put<ApiResponse<any>>(`${this.endpoint}`, payload)).data;
    }
}
