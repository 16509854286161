import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Category, IdToItemMap } from '@/models';
import { ApplicationState } from '@/store';
import { LocationService } from '@/services/location-service';
import { buildMapIdToObject } from '@/utils/helpers/mappers';

const locationService: LocationService = new LocationService();

interface LocationState {
    locations: Category[];
}

const state: LocationState = {
    locations: [],
};

const getters: GetterTree<LocationState, ApplicationState> = {
    LOCATIONS_BY_ID(state: LocationState) {
        const copy: Category[] = JSON.parse(JSON.stringify(state.locations));
        return copy.reduce(buildMapIdToObject, {} as IdToItemMap<Category>);
    },
};

const mutations: MutationTree<LocationState> = {
    SET_LOCATIONS(state: LocationState, payload: typeof state.locations) {
        state.locations = payload;
    }
};

const actions: ActionTree<LocationState, ApplicationState> = {
    async GET_LOCATIONS({ commit }: ActionContext<LocationState, ApplicationState>): Promise<void> {
        const { result } = await locationService.getAll();
        commit('SET_LOCATIONS', result);
    },
};

const namespaced = true;

export const location: Module<LocationState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
