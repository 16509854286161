// Note: Don't change the order. The first one get's displayed as fallback / default.
export const LANGUAGES: any = {
    EN: 'en-GB',
    DE: 'de-DE',
    NL: 'nl-NL',
    HU: 'hu-HU',
};
export const LANGUAGES_N: any = {
    EN: 'en-GB',
    DE: 'de-DE',
    NL: 'nl-NL',
    HU: 'hu-HU',
};
