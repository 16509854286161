import BaseService from '@/utils/base-service';
import { ApiResponse } from '@/models';
import { Filters } from '@/models/filters';

export class FiltersService extends BaseService {
    constructor() {
        super('user-filters');
    }

    public async getFilters(): Promise<ApiResponse<Filters>> {
        return (await this.api.get<ApiResponse<Filters>>(`${this.endpoint}`)).data;
    }

    public async saveFilters(stringifiedFiltersObject: string): Promise<ApiResponse<Filters>> {
        return (await this.api.post<ApiResponse<Filters>>(
            `${this.endpoint}?FiltersAsJson=${stringifiedFiltersObject}`
        )).data;
    }
}
