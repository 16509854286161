import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '@/store';
import { GetDocumentsQuery } from '@/models';
import { FiltersService } from '@/services/filters-service';
import { getStringifiedFilters } from '@/utils/filters-helper';
import { Sort } from '@/models/document';

const filtersService = new FiltersService();
interface FiltersState {
    filters: GetDocumentsQuery;
}

const state: FiltersState = {
    filters: {
        page: 1,
        Severity: [],
        Risk: [],
        Hazard:[],
        Location: [],
        Consequence: [],
        Category: [],
        Fleet: [],
        Editor: [],
        ReviewStatus: [],
        Sort: Sort.Date
    }
}

const getters: GetterTree<FiltersState, ApplicationState> = {
    FILTERS(state) {
        return state.filters;
    },
};

const mutations: MutationTree<FiltersState> = {
    SET_FILTERS(state, payload: GetDocumentsQuery) {
        state.filters = payload
    },
};

const actions: ActionTree<FiltersState, ApplicationState> = {
    async UPDATE_FILTERS({ commit }: ActionContext<FiltersState, ApplicationState>, payload: GetDocumentsQuery) {
        const filters = getStringifiedFilters(payload);
        await filtersService.saveFilters(filters);
    },
    async UPDATE_FILTERS_LOCALLY(
        { commit }: ActionContext<FiltersState, ApplicationState>, payload: GetDocumentsQuery) {
        commit('SET_FILTERS', payload);
    },
};

const namespaced = true;

export const filters: Module<FiltersState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
