
import { Vue } from 'vue-property-decorator';
import { sdk } from './fe-sdk/sdk';
import { LanguageCode } from './translation-helper';

export default class Permissions extends Vue {
    private PERMISSIONS_NAMES: any = {
        LFE_REVIEW: 'LearningsReview',
        LFE_SUPER_REVIEW: 'LearningsSuperReview',
        LFE_TRANSLATION_REVIEW: 'LearningsTranslationReview',
    };

    private LANGUAGES_MAPPER: Record<number, string> = {
        1: LanguageCode.English,
        2: LanguageCode.German,
        3: LanguageCode.Swedish,
    }

    public hasPermission(permissionType: string): any {
        const { permissions } = sdk?.localStorage.getPermissionsLocalStorage();
        return permissions.some((permission: any) => permission.name === permissionType);
    }

    public hasReviewAccess(): boolean {
        return this.hasPermission(this.PERMISSIONS_NAMES.LFE_REVIEW);
    }

    public isTranslationReviewerForLanguage(lang: string): boolean {
        const { permissions, additionalPermissions} = sdk?.localStorage.getPermissionsLocalStorage();
        return permissions.some(
            (p: any) => p.name === this.PERMISSIONS_NAMES.LFE_TRANSLATION_REVIEW &&
                additionalPermissions.some(
                    (ap: any) => ap.userGroupId === p.userGroupId &&
                        this.LANGUAGES_MAPPER[ap.additionalPermissionId] === lang
                )
        )
    }

    public hasSuperReviewAccess(): boolean {
        return this.hasPermission(this.PERMISSIONS_NAMES.LFE_SUPER_REVIEW);
    }
}
