<template>
    <div
        class="filters-container flex flex-col"
    >
        <div class="flex-container pl-1 pr-2 filters-container__header">
            <pui-button
                class="alert-btn"
                v-pui-tooltip="{ message: $t('filters.alertMeTooltip')}"
                small
                icon="mail"
                @click="goToSettings"
            >
                {{ $t('actions.setUpEmailAlerts') }}
            </pui-button>
        </div>
        <div class="scrollable-filters">
            <collapsible-filter
                v-for="filter in multiCheckFilters"
                :key="`filter-${filter.name}`"
                class="mt-2"
                :header-title="filter.label"
                :total-selected-options="getSelectedOptionsForFilter(filter.name).length"
                @clear="resetSelectedOptionsOfFilter(filter.name)"
            >
                <template #content>
                    <multi-check-tree
                        class="checkbox-tree"
                        :key="`filter-${filter.name}`"
                        :open="filter.open"
                        :filter-property="filter.name"
                        :show-search="filter.appliedOptions.length > 5"
                        :options="filter.options"
                        :selected="getSelectedOptionsForFilter(filter.name)"
                        :favorited="getFavoriteFilterIdsByName(filter.name)"
                        :show-tooltip-for-tree-nodes="true"
                        @update="handleMultiCheckUpdate"
                    />
                </template>
            </collapsible-filter>
            <collapsible-filter
                class="mt-2"
                :header-title="$t('document.editor')"
                :total-selected-options="editorFilterValue.length"
                @clear="handleEditorChanged([])"
            >
                <template #content>
                    <pui-form-group
                        v-if="editors.length"
                        class="mb-5 pl-1 pr-1"
                        :label="$t('document.editor')"
                        label-for="editor"
                    >
                        <pui-form-multi-select
                            data-cy="filterEditor"
                            :label="$t('document.editor')"
                            search-input-id="editor"
                            :search-input-placeholder="$t('actions.search')"
                            :options="editorsOptions"
                            :value="editorFilterValue"
                            @change="handleEditorChanged"
                        />
                    </pui-form-group>
                </template>
            </collapsible-filter>
        </div>
        <div
            class="action-buttons"
        >
            <hr
                v-if="numberOfAppliedFilters"
                class="mt-0 mb-1"
            >
            <div
                v-if="numberOfAppliedFilters"
                class="pl-1 pr-1 flex-container flex-center justify-between mb-1"
            >
                <span>{{ `${ numberOfAppliedFilters } ${$t('filters.filtersApplied')}` }}</span>
                <pui-context-menu v-if="areFavoriteFiltersExistent()">
                    <pui-button
                        slot="context-menu-trigger"
                        class="side-section-title__action"
                        small
                        icon="reset"
                    >
                        {{ $t('actions.clear') }}
                    </pui-button>
                    <pui-context-menu-item
                        v-for="action, index in clearActions"
                        :key="index"
                        :primary="action.isPrimary"
                        :label="action.value"
                        @click.native="handleClearAction(action.value)"
                    />
                </pui-context-menu>
                <pui-button
                    v-else
                    slot="context-menu-trigger"
                    class="side-section-title__action"
                    small
                    icon="reset"
                    @click="clearFilters"
                >
                    {{ $t('actions.clear') }}
                </pui-button>
            </div>
            <pui-button
                class="button--square"
                @click="handleApplyFiltersClick"
            >
                {{ $t('actions.applyFilters') }}
            </pui-button>
            <pui-button
                class="button--square mt-1"
                state="secondary"
                v-pui-tooltip="{ message: $t('actions.saveAppliedFiltersAsFavoriteTooltip')}"
                :disabled="!canAddFiltersToFavorites"
                icon="star-active"
                @click="handleConfirmFavoritesModalOpen"
            >
                {{ $t('actions.saveAppliedFiltersAsFavorite') }}
            </pui-button>
        </div>
        <save-filters-modal
            ref="favoritesModal"
            :title="$t('favoritesModal.title')"
            :description="$t('favoritesModal.description')"
            @confirm="handleSaveFavoriteClick"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapGetters, mapState } from 'vuex';
import { Category, GetDocumentsQuery, IdToItemMap, MultiCheckFilter, MultiselectOption, Profile } from '@/models';
import AppliedFilters from '@/components/filters/applied-filters.vue';
import SaveFiltersModal from '@/components/filters/save-filters-modal.vue'
import CollapsibleFilter from '@/components/filters/collapsible-filter.vue';
import {
    FILTERS,
    initializeFilters,
    numberOfAppliedFilters,
    getReviewStatusOptions,
    riskAndSeverityOptions
} from '@/utils/filters-helper';
import { FiltersService } from '@/services/filters-service';
import MultiCheckTree from './multi-check-tree.vue';
import {
    getPersistedFavoriteFilters,
    getPersistedFilterIdsByName,
    persistFavoriteFilters
} from '@/utils/helpers/filters-persistence';
import { isNil } from 'lodash';

@Component({
    name: 'side-filters',
    components: { AppliedFilters, SaveFiltersModal, CollapsibleFilter, MultiCheckTree },
    computed: {
        ...mapState('document', ['editors']),
        ...mapState('category', ['categories']),
        ...mapState('location', ['locations']),
        ...mapState('fleet', ['fleets']),
        ...mapState('hazard', ['hazards']),
        ...mapState('consequence', ['consequences']),
        ...mapGetters({
            CATEGORIES_BY_ID: 'category/CATEGORIES_BY_ID',
            LOCATIONS_BY_ID: 'location/LOCATIONS_BY_ID',
            FLEETS_BY_ID: 'fleet/FLEETS_BY_ID',
            HAZARDS_BY_ID: 'hazard/HAZARDS_BY_ID',
        }),
    },
})
export default class SideFilters extends Vue {
    private categories!: Category[];
    private readonly CATEGORIES_BY_ID!: IdToItemMap<Category>;
    private locations!: Category[];
    private readonly LOCATIONS_BY_ID!: IdToItemMap<Category>;
    private fleets!: Category[];
    private readonly FLEETS_BY_ID!: IdToItemMap<Category>;
    private hazards!: Category[];
    private readonly HAZARDS_BY_ID!: IdToItemMap<Category>;
    private consequences!: Category[];
    private readonly editors!: Profile[];
    private editorFilterKey = FILTERS.EDITOR;
    private canAddFiltersToFavorites = false;
    private filtersService = new FiltersService();
    private query: any = null;
    private $pui!: { toast: Function };
    private clearActions = [
        {
            value: this.$t('actions.clearAllFilters'),
            isPrimary: true
        },
        {
            value: this.$t('actions.resetToFavorites'),
            isPrimary: false
        }
    ];

    private mapTranslatedOptions(list: Category[]): Category[] {
        return list.map((item) => {
            return {
                ...item,
                name: this.$tc(item.name)
            };
        });
    }

    private riskAndSeverityOptions = this.mapTranslatedOptions(Object.values(riskAndSeverityOptions));
    private reviewStatusOptions = this.mapTranslatedOptions(Object.values(getReviewStatusOptions()));

    private mounted(): void {
        if (this.numberOfAppliedFilters > 0) {
            const routeQuerry = initializeFilters(this.$route.query);
            this.applyFilters(routeQuerry);
            return;
        }
        const localStorageQuery = {
            ...initializeFilters(getPersistedFavoriteFilters()),
            page: this.$route.query.page,
            Sort: this.$route.query.Sort
        };
        this.applyFilters(localStorageQuery);
    }

    private get editorsOptions(): MultiselectOption[] {
        return this.editors.map(({ name, userKid }) => ({ label: name!, value: userKid! }));
    }

    private get editorFilterValue(): MultiselectOption[] {
        if (isNil(this.query)) {
            return [];
        }

        return this.query?.[this.editorFilterKey] ?? [];
    }

    private get multiCheckFilters(): MultiCheckFilter[] {
        return [
            {
                label: this.$tc('document.category'),
                name: 'Category',
                options: this.categories,
                open: false,
                appliedOptions: Object.values(this.CATEGORIES_BY_ID),
            },
            {
                label: this.$tc('document.location'),
                name: 'Location',
                options: this.locations,
                open: false,
                appliedOptions: Object.values(this.LOCATIONS_BY_ID),
            },
            {
                label: this.$tc('document.fleet'),
                name: 'Fleet',
                options: this.fleets,
                open: false,
                appliedOptions: Object.values(this.FLEETS_BY_ID),
            },
            {
                label: this.$tc('document.hazard'),
                name: 'Hazard',
                options: this.hazards.filter(hazard => hazard.id !== 0 && hazard.id !== -1),
                open: false,
                appliedOptions: Object.values(this.HAZARDS_BY_ID),
            },
            {
                label: this.$tc('document.consequence'),
                name: 'Consequence',
                options: this.consequences.filter(cons => cons.id !== 1 && cons.id !== -1),
                open: false,
                appliedOptions: this.consequences,
            },
            {
                label: this.$tc('document.severity'),
                name: 'Severity',
                options: this.riskAndSeverityOptions,
                open: true,
                appliedOptions: this.riskAndSeverityOptions,
            },
            {
                label: this.$tc('document.risk'),
                name: 'Risk',
                options: this.riskAndSeverityOptions,
                open: true,
                appliedOptions: this.riskAndSeverityOptions,
            },
            {
                label: this.$tc('document.reviewStatus'),
                name: 'ReviewStatus',
                options: this.reviewStatusOptions,
                open: true,
                appliedOptions: this.reviewStatusOptions,
            },
        ];
    }

    private get numberOfAppliedFilters(): number {
        return numberOfAppliedFilters(this.$route.query);
    }

    private areFavoriteFiltersExistent(): boolean {
        const filters = getPersistedFavoriteFilters();
        return numberOfAppliedFilters(filters) > 0 && this.canAddFiltersToFavorites;
    }

    private getFavoriteFilterIdsByName(filterName: string): any {
        return getPersistedFilterIdsByName(filterName);
    }

    private clearFilters(): void {
        const { page, Sort, ...rest } = this.$route.query;
        if (Object.keys(rest).length) {
            this.$router.push({ query: { page, Sort } });
        }
        this.query = initializeFilters();
    }

    private applyFilters(query: any): void {
        this.updateQuery(query);
        this.$router.push({query});
    }

    private updateQuery(query: any): void {
        this.query = initializeFilters(query);
        const localStorageQuery = initializeFilters(getPersistedFavoriteFilters());
        this.canAddFiltersToFavorites = JSON.stringify(localStorageQuery) !== JSON.stringify(this.query);
    }

    private getSelectedOptionsForFilter(filterName: string): any {
        if (isNil(this.query)){
            return [];
        }

        return this.query[filterName].map(
            (value: string | number) => !isNaN(Number(value)) ? Number(value) : value
        );
    }

    private resetSelectedOptionsOfFilter(filterName: string): any {
        this.query[filterName] = [];
    }

    private goToSettings (): void {
        this.$router.push({name: 'Settings'})
    }

    private handleConfirmFavoritesModalOpen(): void {
        (this.$refs.favoritesModal as any).open();
    }

    private handleApplyFiltersClick(): void {
        this.applyFilters(this.query);
    }

    private handleSaveFavoriteClick(): void {
        persistFavoriteFilters(this.query);
        this.canAddFiltersToFavorites = false;
        (this.$refs.favoritesModal as any).close();
        this.$pui.toast({
            type: 'success',
            title: this.$t('filters.favoriteFiltersSuccessfullySaved'),
            copy: '',
        });
    }

    private handleMultiCheckUpdate(value: any): void {
        const key: any = Object.keys(value)[0];
        this.query = {
            ...this.query,
            [key]: Object.values(value[key])
        }
    }

    private handleEditorChanged(value: string[]): void {
        (this.query as GetDocumentsQuery)['Editor'] = value;
    }

    private handleClearAction(action: any): void {
        if (action === this.$t('actions.clearAllFilters')) {
            this.clearFilters();
            return;
        }
        const localStorageQuery = initializeFilters(getPersistedFavoriteFilters());
        this.applyFilters(localStorageQuery);
    }

    @Watch('$route.query')
    onRouteChanged(query: any): void {
        this.updateQuery(query);
    }
}
</script>

<style lang="less" scoped>
@import '../../variables.less';

 .side-section-title {
    text-transform: uppercase;
    color: @medium-grey;
    display: flex;
    justify-content: space-between;

    /deep/ &__action {
        text-transform: none;
        svg {
            color: unset;
        }
    }
}

.filters-container {
    height: 100%;
    .scrollable-filters {
        flex-grow: 9;
        overflow-y: auto;
    }

    .action-buttons {
        background-color: @pale-grey;
        padding: 1rem 2rem 1rem 2rem;
    }

    &__header {
        background-color: #99DFFF1A;
        border: 1px solid #0078DC40;
    }
}
.button--square {
    text-transform: none;
    width: 100%;
    height: 29px;

    &.disabled {
        filter: opacity(50%) !important;
    }
}
/deep/ .alert-btn {

    svg {
        width: 24px;
        height: 24px;
        max-height: unset;
        max-width: unset;
        margin-right: 0.4rem;
    }
}

.checkbox-tree {
    /deep/ .pui-tree__search > .pui-tree__search-input {
        width: 100%;
    }

    /deep/ .pui-tree-node .pui-tree-node__content-wrapper {
        height: 35px;
    }

    /deep/ .pui-tree__search {
        padding-top: 1rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
    }

    /deep/ .pui-tree-node--level-1 {

        .pui-tree-node__content-wrapper {
            padding-right: 8px !important;

            .pui-tree-node__content {
                margin-left: 0;
            }
        }
    }

    /deep/ .pui-tree-node--level-2 {
        .pui-tree-node__content-wrapper {
            padding-left: 35px !important;
        }
    }

    /deep/ .pui-tree-node--level-3 {
        .pui-tree-node__content-wrapper {
            padding-left: 56px !important;
        }
    }
}
</style>
