import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Category, IdToItemMap, TranslationParams } from '@/models';
import { ApplicationState } from '@/store';
import { HazardService } from '@/services/hazard-service';
import { buildMapIdToObject } from '@/utils/helpers/mappers';
import { getRootTranslationKey, getTranslationForCategory } from '@/utils/translation-helper';

const hazardService: HazardService = new HazardService();

interface HazardState {
    hazards: Category[];
}

const state: HazardState = {
    hazards: [],
};

const getters: GetterTree<HazardState, ApplicationState> = {
    HAZARDS_BY_ID(state: HazardState) {
        const copy: Category[] = JSON.parse(JSON.stringify(state.hazards));
        return copy.reduce(buildMapIdToObject, {} as IdToItemMap<Category>);
    },
};

const mutations: MutationTree<HazardState> = {
    SET_HAZARDS(state: HazardState, payload: typeof state.hazards) {
        state.hazards = payload;
    }
};

const actions: ActionTree<HazardState, ApplicationState> = {
    async GET_HAZARDS(
        { commit }: ActionContext<HazardState, ApplicationState>,
        params: TranslationParams,
    ): Promise<void> {
        const { result } = await hazardService.getAll();
        commit('SET_HAZARDS', result.map(hazard => getTranslationForCategory({
            category: hazard,
            rootTranslationKey: getRootTranslationKey('hazards'),
            selectedLanguage: params.selectedLanguage,
            translations: params.translations,
        })));
    },
};

const namespaced = true;

export const hazard: Module<HazardState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
