import { Category, CategoryTranslationParams } from '@/models/category';

export enum LanguageCode {
    English = 'en',
    German = 'de',
    Swedish = 'sv',
}

const requiredLanguages = [LanguageCode.English, LanguageCode.German] as string[];
const optionalLanguages = [LanguageCode.Swedish] as string[];
const fallbackLang = LanguageCode.English;

export const getLanguageFlag = (code: LanguageCode): string => {
    return {
        [LanguageCode.English]: 'gb',
        [LanguageCode.German]: 'de',
        [LanguageCode.Swedish]: 'se',
    }[code];
}

const getRootTranslationKey = (
    type: 'categories' | 'consequences' | 'locations' | 'fleets' | 'hazards'
): string => {
    if (type === 'categories') return 'sideFilters.category.';
    if (type === 'fleets') return 'sideFilters.technology.';
    if (type === 'consequences') return 'sideFilters.consequence.';
    if (type === 'hazards') return 'sideFilters.hazard.';
    return '';
}

const getTranslationForCategory = (
    {selectedLanguage, category, translations, rootTranslationKey}: CategoryTranslationParams
): Category => {
    if (selectedLanguage === 'en-US') {
        return category;
    }

    const messages = {
        fallbackLangMessages: translations['en-US'],
        activeLangMessages: translations[selectedLanguage],
    };
    if (!rootTranslationKey || !messages.activeLangMessages || !messages.fallbackLangMessages) {
        return category;
    }

    let translatedName = category.name;
    for (const key in messages.fallbackLangMessages) {
        if (
            key.includes(rootTranslationKey) &&
            messages.fallbackLangMessages[key] === category.name
        ) {
            translatedName = messages.activeLangMessages[key] as string;
            break;
        }
    }
    return {
        ...category,
        name: translatedName,
        children: category.children.map(ch => getTranslationForCategory({
            selectedLanguage,
            category: ch,
            translations,
            rootTranslationKey
        }))
    }
}

export { requiredLanguages, optionalLanguages, fallbackLang, getTranslationForCategory, getRootTranslationKey };
