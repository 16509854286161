<template>
    <pui-date-picker
        :start.sync="range.from"
        :end.sync="range.to"
        :show.sync="localShow"
        use-calendar
        :use-input="false"
        :use-checkbox="false"
        days-only
        @reset="reset"
        @apply="update"
    >
        <div
            class="buttton-custom flex-container flex-center pointer"
            :class="{'applied': showClearButton}"
            @click="localShow = !localShow"
        >
            <div class="button-content flex-container flex-center justify-between">
                <div
                    class="pointer flex-container flex-center"
                >
                    <pui-icon
                        icon-name="calendar"
                        class="mr-1 clear-icon"
                    />
                    <span>
                        <span v-if="range.from || range.to">{{ range.from }} - {{ range.to }}</span>
                        <span v-else>{{ $t('actions.filterByIncident') }}</span>
                    </span>
                </div>

                <pui-icon
                    v-if="showClearButton"
                    icon-name="close"
                    class="ml-2 pointer"
                    @click.native="reset"
                />
            </div>
        </div>
    </pui-date-picker>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'date-range',
})
export default class DateRange extends Vue {
    private range = {
        from: this.$route.query.DateFrom as string | '',
        to: this.$route.query.DateTo as string | '',
    };
    private localShow = false;

    private reset(): void {
        this.range = {
            from: '',
            to: '',
        };
        const query = { ...this.$route.query };
        if (query.DateFrom || query.DateTo) {
            delete query.DateFrom;
            delete query.DateTo;
            this.$router.push({ query });
        }
    }

    private update(): void {
        const query = { ...this.$route.query };
        if (query.DateFrom !== this.range.from || query.DateTo !== this.range.to) {
            this.$router.push({
                query: {
                    ...this.$route.query,
                    page: '1',
                    DateFrom: this.range.from,
                    DateTo: this.range.to,
                },
            });
        }
    }

    private get showClearButton(): boolean {
        return !!this.$route.query?.DateFrom || !!this.$route.query?.DateTo;
    }
}
</script>

<style lang="less">
@import '../../variables.less';
.buttton-custom {
    width: 35rem;
    height: 4rem;
    border: 1px solid @warm-grey;
    border-radius: 5px;
    background-color: #fff;
    padding: 0.9rem 1.8rem 0.9rem;

    &.applied {
        justify-content: space-between;
    }

    .button-content {
        width: 100%;

        .clear-content {
            color: @warm-grey;
        }
    }
}

.pointer {
    cursor: pointer;
}

.pui-date-picker.pui-date-picker--calendar-only{
    width: auto;
}
</style>
