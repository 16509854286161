import Vue from 'vue';
import { Notification } from './contracts/Notification';

interface NotificationsState {
    notifications: Array<Notification>;
    showNotifications: boolean;
}

export const notifications = {
    namespaced: true,
    state: (): any => ({
        notifications: Array<Notification>(),
        showNotifications: true,
    }),
    getters: {
        notifications: (state: any): Notification[] => state.notifications,
        getNotificationById: (state: any, notificationId: number):
        Notification => state.notifications.find((n: Notification) => n.id === notificationId),
        showNotifications: (state: any): boolean => state.showNotifications,
    },
    mutations: {
        notifications: (state: any, notis: Notification[]): void => {
            state.notifications = notis;
        },
        addNotification: (state: any, notification: Notification): void => {
            state.notifications.push(notification);
        },
        showNotifications: (state: any, shouldShow: boolean): void => {
            state.showNotifications = shouldShow;
        },
        markNotificationAsRead: (state: any, notification: Notification): void => {
            const idx = state.notifications.findIndex((n: Notification) => n.id === notification.id);

            if (idx !== -1) {
                notification.isRead = true;
                Vue.set(state.notifications, idx, notification);
            }
        },
    },
    actions: {
        markNotificationAsRead: ({ commit }: any, notification: Notification): void => {
            commit('showNotifications', false);
            commit('markNotificationAsRead', notification);
            setTimeout(() => commit('showNotifications', true), 0.1);
        },
        addNotification: ({ commit, state }:
            { commit: any; state: NotificationsState }, notification: Notification): void => {
            commit('showNotifications', false);
            commit('addNotification', notification);
            state.notifications = state.notifications.sort((a, b) =>
                new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
            setTimeout(() => commit('showNotifications', true), 0.1);
        },
    },
};
