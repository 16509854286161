import axios, { AxiosError } from 'axios';
import { HttpStatusCodes, UseCaseListDto } from '../../models';
import { UseCaseService } from '../../services/use-case-service';

interface UseCaseState {
    useCases: Array<UseCaseListDto>;
}

export const useCases = {
    namespaced: true,
    state: (): UseCaseState => ({
        useCases: [],
    }),
    getters: {
        getAllUseCases: (state: UseCaseState): Array<UseCaseListDto> => state.useCases,
        getUseCaseById: (state: UseCaseState): (id: number) =>
        UseCaseListDto => (id: number): UseCaseListDto => state.useCases.filter(x => x.id === id)[0],
    },
    actions: {
        fetchUseCases: async ({ commit }: any): Promise<number> => {
            const service = new UseCaseService();
            try {
                const useCaseList = await service.getUseCases();
                commit('SET_USE_CASES', useCaseList.result.items);
            }
            catch (e) {
                if (axios.isAxiosError(e)) {
                    return (e as AxiosError).response?.status ?? HttpStatusCodes.InternalServerError;
                } else {
                    console.error(`An unknown error occured while fetching the use cases.`);
                    console.error(e);
                    return HttpStatusCodes.InternalServerError;
                }
            }
            return HttpStatusCodes.Ok;
        },
    },
    mutations: {
        SET_USE_CASES (state: UseCaseState, useCases: Array<UseCaseListDto>): void {
            state.useCases = useCases;
        },
    }
};
