import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/components/home/home.vue';
import Settings from '@/components/settings/settings.vue';
import { Permissions } from '@/models';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings
    },
    {
        path: '/document/add',
        name: 'DocumentAdd',
        component: (): Promise<any> => import(
            /* webpackChunkName: "DocumentAdd" */ '../components/document/document-learning/document-learning-add.vue'
        ),
        meta: {
            requiresPermission: Permissions.Creator,
        },
        beforeEnter(to, from, next): void {
            if (!from.name) {
                next({ name: 'Home', query: { page: '1', Sort: 'Date'} });
            } else {
                next();
            }
        },
    },
    {
        path: '/document/:documentNumber',
        name: 'Document',
        component: (): Promise<any> => import(
            /* webpackChunkName: "Document" */ '../components/document/document-learning/document-learning.vue'
        ),
    },
    {
        path: '/no-permissions',
        name: 'NoPermissions',
        component: (): Promise<any> => import(
            /* webpackChunkName: "Permissions" */ '../components/permissions/no-permissions.vue'
        ),
    },
    {
        path: '/*',
        redirect: '/',
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;
