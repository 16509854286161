<template>
    <div>
        <pui-form-input-selector
            name="sort"
            :options="options"
            :selected-value="selectedValue"
            @update:selected-value="handleSortChanged"
        />
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { MultiselectOption } from '@/models';

@Component({
    name: 'top-filters',
})
export default class TopFilters extends Vue {
    private options: MultiselectOption[] = [
        {
            label: this.$t('mostRecent') as string,
            value: 'Date',
        },
        {
            label: this.$t('mostPopular') as string,
            value: 'Popularity',
        },
        {
            label: this.$t('mylearning') as string,
            value: 'LoggedInUser',
        },
    ];

    private get selectedValue(): any {
        const {Sort} = this.$route.query;
        const sortingOptionIsValid = this.options.filter(option => option.value === Sort)?.length > 0;

        if(Sort && sortingOptionIsValid) {
            return Sort;
        }

        return this.options[0].value;
    }

    private handleSortChanged(value: string): void {
        this.$router.push({
            query: {
                ...this.$route.query,
                Sort: value
            }
        })
    }
}
</script>

<style scoped>

</style>
