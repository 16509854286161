<template>
    <pui-lightbox
        ref="saveFilters"
        :default-footer-confirm-label="$t('actions.continue')"
        :default-footer-cancel-label="$t('actions.cancel')"
        :show-lightbox-close-icon="false"
        :on-cancel-callback="close"
        :on-confirm-callback="handleOnConfirm"
        :fit-content="true"
    >
        <template #lightbox-header>
            <h3>{{ title }}</h3>
        </template>
        <template #default>
            <div class="description pt-2 pb-2">
                <span>
                    {{ description }}
                </span>
            </div>
        </template>
    </pui-lightbox>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'save-filters-modal',
})
export default class SynergiAttachment extends Vue {
    @Prop({ type: String, default: '' }) readonly title!: string;

    @Prop({ type: String, default: '' }) readonly description!: string;

    private open(): void {
        (this as any).$refs.saveFilters.isOpen = true;
    }

    private close(): void {
        (this.$refs.saveFilters as any).close();
    }

    private handleOnConfirm(): void {
        this.close();
        this.$emit('confirm');
    }
}
</script>

<style scoped lang="less">
@import '../../variables.less';
.description {
    width: 70rem;
    text-align: justify;
}

.warning {
    color: @medium-orange;
}

span {
    line-height: 1.25;
    color: #4A4A4A;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
  }
</style>
