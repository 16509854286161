<template>
    <div class="card document-card">
        <div class="document-card__container">
            <div class="document-card__details">
                <pui-badge
                    data-cy="cardDocumentHighestRisk"
                    v-bind="getColors(potentialRiskkey)"
                    class="mb-1"
                >
                    {{ `${$t('document.risk')}: ${getpotentialRisk}` }}
                </pui-badge>

                <pui-badge
                    data-cy="cardDocumentHighestSeverity"
                    v-bind="getColors(actualseveritykey)"
                    class="mb-2"
                >
                    {{ `${$t('document.severity')}: ${getactualseverity}` }}
                </pui-badge>

                <div class="document-card__event-details">
                    <div class="document-card__event-details-entry mb-1">
                        <pui-icon
                            icon-name="folder-open"
                            size="2.4rem"
                            class="document-card__event-detail-icon"
                        />
                        <div
                            v-pui-tooltip="{ message: getComputed('categories') }"
                            data-cy="cardDocumentCategory"
                            class="document-card__event-details-title"
                        >
                            {{ getComputed('categories') }}
                        </div>
                    </div>
                    <div class="document-card__event-details-entry mb-1">
                        <pui-icon
                            icon-name="location"
                            size="2.4rem"
                            class="document-card__event-detail-icon"
                        />
                        <div
                            v-pui-tooltip="{ message: getComputed('locations') }"
                            data-cy="cardDocumentLocation"
                            class="document-card__event-details-title"
                        >
                            {{ getComputed('locations') }}
                        </div>
                    </div>
                    <div class="document-card__event-details-entry mb-1">
                        <pui-icon
                            icon-name="station"
                            size="2.4rem"
                            class="document-card__event-detail-icon"
                        />
                        <div
                            v-pui-tooltip="{ message: getComputed('fleets') }"
                            data-cy="cardDocumentFleet"
                            class="document-card__event-details-title"
                        >
                            {{ getComputed('fleets') }}
                        </div>
                    </div>
                    <div class="document-card__event-details-entry">
                        <pui-icon
                            icon-name="calendar"
                            size="2.4rem"
                            class="document-card__event-detail-icon"
                        />
                        <div
                            data-cy="cardDocumentDate"
                            class="document-card__event-details-title"
                        >
                            <span
                                v-if="document.cases.length > 0"
                                v-pui-tooltip="{ message: formatDateTime(document.cases[0].caseDateTime) }"
                            >
                                {{ formatDateTime(document.cases[0].caseDateTime) }}
                            </span>
                            <span
                                v-else
                                v-pui-tooltip="{ message: $t('noData') }"
                            >{{ $t('noData') }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="document-card__content">
                <router-link
                    :to="{
                        name: 'Document',
                        params: { documentNumber: document.documentNumber.replaceAll('/', '-') },
                    }"
                    custom
                    v-slot="{ navigate }"
                >
                    <div
                        data-cy="cardDocumentTitle"
                        class="document-card__content-title mb-1"
                        :class="{'cursor-pointer': $route.name === 'Home'}"
                        @click="navigate"
                    >
                        {{ title }}
                    </div>
                </router-link>
                <div class="document-card__content-subtitle mb-2">
                    <span
                        v-if="document.issuedBy"
                        data-cy="cardDocumentReviewedBy"
                        class="mr-2"
                    >
                        {{ $t('document.form.issuedBy.label') }}: {{
                            document.issuedBy.name ? document.issuedBy.name : '-'
                        }}
                    </span>
                    <span
                        v-if="document.approvedBy"
                        data-cy="cardDocumentReviewedBy"
                    >
                        {{ $t('document.form.approvedBy.label') }}: {{
                            document.approvedBy.name ? document.approvedBy.name : '-'
                        }}
                    </span>
                </div>
                <div
                    v-html="teaserText"
                    class="document-card__content-message"
                />
            </div>
            <div class="document-card__image-container">
                <div
                    v-if="document.teaserPictureUri"
                    class="document-card__image-wrapper"
                    :style="{
                        background: `url(${teaserUri})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: 'center',
                        backgroundPositionY: 'top'
                    }"
                />
                <teaser-placeholder v-else />
            </div>
        </div>

        <document-card-footer
            enable-three-columns-grid
            :document="document"
            class="document-card__footer"
        />
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getBadgeColors } from '@/utils/helpers/badge-colors';
import { getNames } from '@/utils/helpers/mappers';
import { formatDateTime } from '@/utils/helpers/formatters';
import { Category, Document } from '@/models';
import { fallbackLang, getRootTranslationKey, getTranslationForCategory } from '@/utils/translation-helper';
import DocumentCardFooter from './document-card-footer.vue';
import TeaserPlaceholder from './teaser-placeholder.vue';
import { sdk } from '@/utils/fe-sdk/sdk';
import { translateRiskAndSeverityOptions } from '@/utils/filters-helper';

@Component({
    name: 'documents-list-item',
    components: { DocumentCardFooter, TeaserPlaceholder },
})

export default class DocumentsListItem extends Vue {
    @Prop(Object) readonly document!: Document;
    private lang = sdk?.translation.uiTranslation.currentLanguage;

    private get teaserUri(): URL {
        return new URL(this.document.teaserPictureUri || '');
    }

    private get title(): string {
        return this.getDocumentProperty('title');
    }

    private get content(): string {
        return this.getDocumentProperty('whatHappened');
    }

    private getDocumentProperty(property: string): string {
        const preferredLangContent: any = this.document.documentContents
            .find((content): boolean => content.locale === this.lang.substring(0, 2));
        const defaultLangContent: any = this.document.documentContents
            .find((content): boolean => content.locale === fallbackLang);
        if (preferredLangContent) {
            return preferredLangContent[property];
        } else if (defaultLangContent) {
            return defaultLangContent[property];
        } else {
            return (this.document.documentContents[0] as any)[property];
        }
    }

    private potentialRiskkey: any ='';
    private get getpotentialRisk(): any {
        this.potentialRiskkey = this.document.highestRisk == '0'
            ? 'NotApplicable'
            : this.document.highestRisk;
        return this.$i18n.t(translateRiskAndSeverityOptions(this.potentialRiskkey));
    }

    private actualseveritykey: any ='';
    private get getactualseverity(): any {
        this.actualseveritykey = this.document.highestSeverity == '0'
            ? 'NotApplicable'
            : this.document.highestSeverity
        return this.$i18n.t(translateRiskAndSeverityOptions(this.actualseveritykey));
    }

    private getComputed(property: 'categories' | 'consequences' | 'locations' | 'fleets' | 'hazards'): string {
        return this.document.cases
            .map((synergiCase) => {
                const data = synergiCase[property] as Category[];
                return data
                    .map(d => getTranslationForCategory({
                        category: d,
                        rootTranslationKey: getRootTranslationKey(property),
                        selectedLanguage: this.lang,
                        translations: this.$i18n.messages as any,
                    }))
                    .map(getNames)
                    .join(' | ');
            })
            .filter(Boolean)
            .join(' || ');
    }

    private get teaserText(): string {
        return this.content
            .replace('&nbsp;','') // Replace &nbsp; with no space ''
            .replace('&amp;', '&') // Replace &amp; with &
            .replace('&lt;', '<') // Replace &lt; with <
            .replace('&gt;', '>') // Replace &gt; with >
            .replace('&quot;', '"') // Replace &quot; with "
            .replace('&apos;', "'") // Replace &apos; with '
            .replace('&hellip;', '...') // Replace &hellip; with ...
            .replace('&mdash;', '—') // Replace &mdash; with —
            .replace('&ndash;', '–') // Replace &ndash; with –
            .replace('&rsquo;', '’') // Replace &rsquo; with ’
            .replace('&lsquo;', '‘');// Replace &lsquo; with ‘
    }

    formatDateTime = formatDateTime;
    getColors = getBadgeColors;
}
</script>
<style lang="less">
@import '../../variables.less';
@document-card-gap: 2.3rem;
.document-card {
    padding: 4.2rem 3rem 2rem 2.7rem;
}

.document-card__container {
    display: flex;
    gap: @document-card-gap;
    margin-bottom: 3rem;
}

.document-card__details {
    flex: 1 0;
    width: 22%;

    .pui-badge {
        width: 100%;
        text-transform: none;
        height: fit-content;
        font-size: 1.4rem;
        line-height: 2.4rem;
        padding: 0 1.2rem;
    }
}
.document-card__event-details {
    border-radius: 4px;
    background-color: @warm-grey-25;
    font-size: 1.4rem;
    padding: 1.1rem 1.2rem 1.8rem 1.3rem;
    display: flex;
    flex-direction: column;
}
.document-card__event-details-entry {
    display: inline-flex;
    min-width: 0;
}
.document-card__event-detail-icon {
    flex-shrink: 0;
}
.document-card__event-details-title {
    padding-left: 1rem;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 8px;
    cursor: pointer;
}

.document-card__content {
    flex: 2 1;
    width: 50%;
}
.document-card__content-title {
    font-family: 'Roboto-Medium', sans-serif;
    font-size: 2.4rem;
    color: @dark-grey;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: block;
}
.document-card__content-subtitle {
    font-size: 1.2rem;
    color: @warm-grey;
}
.document-card__content-message {
    color: @dark-grey;
    font-size: 1.6rem;
    line-height: 2.4rem;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
    }

    ul, ol {
        display: block !important;
        list-style-type: initial !important;
        margin: 0 !important;
        padding-inline-start: 40px !important;
    }

    li {
        line-height: 1.5 !important;
        border-bottom: none !important;
    }

    ul li {
        list-style-type: initial !important;
    }
}

.document-card__image-container {
    flex: 1 0;
    width: 25%;
}
.document-card__image-wrapper {
    width: 100%;
    height: 100%;
}

.document-card__footer {
    border-top: 1px solid @warm-grey-25;
    padding-top: 0.8rem;
}
</style>
