<template>
    <div id="app">
        <pui-loader
            v-if="!isAppReady"
            :promise="promise"
        />
        <PuiApplicationLayout v-else>
            <template #header>
                <header-wrapper :applications="applications" />
            </template>

            <template
                v-if="$route.name === 'Home' || $route.name === 'Settings'"
                #left
            >
                <nav
                    data-cy="leftSidePanel"
                    class="pui-side-panel left-panel"
                >
                    <PuiSidePanel
                        v-if="$route.name === 'Home'"
                        :open.sync="isSidePanelOpen"
                        :floating-trigger="false"
                        width="40rem"
                    >
                        <side-filters />
                    </PuiSidePanel>
                </nav>
            </template>

            <template #main>
                <main class="main pb-2">
                    <pui-grid-container>
                        <pui-grid-row>
                            <pui-grid-column :cols="12">
                                <router-view :key="$route.name" />
                            </pui-grid-column>
                        </pui-grid-row>
                    </pui-grid-container>
                </main>
            </template>
        </PuiApplicationLayout>

        <pui-tooltip />

        <pui-lightbox
            ref="confirmationBox"
            :default-footer-confirm-label="confirmation && confirmation.confirm"
            :default-footer-cancel-label="confirmation && confirmation.cancel"
            fit-content
            :show-lightbox-close-icon="false"
            :on-cancel-callback="() => confirmation.reject()"
            :on-confirm-callback="() => confirmation.resolve()"
        >
            <pui-headline
                v-if="confirmation"
                type="h4"
                class="pt-1 text-center"
            >
                {{ confirmation.title }}?
            </pui-headline>
            <div
                v-if="confirmation"
                class="mt-1 text-center"
            >
                <span v-if="confirmation.content">{{ confirmation.content }}</span>
            </div>
        </pui-lightbox>
    </div>
</template>

<script lang="ts">
import { Component, Provide, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import SideFilters from '@/components/filters/side-filters.vue';
import { HubConnectionBuilder, LogLevel, HttpTransportType  } from '@microsoft/signalr';
import './main.less';
import { Application, Confirmation, ConfirmationOptions } from '@/models';
import { AppService, NotificationService } from '@/services';
import { MenuEntry } from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { EventBus } from './utils';
import { sdk } from './utils/fe-sdk/sdk';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        SideFilters,
    },
})
export default class App extends Vue {
    private $pui!: { toast: Function };
    private isAppReady = false;
    private confirmation: Confirmation | null = null;
    private signalConnection: any = null;
    private applications: MenuEntry[] = [];
    private promise: any = null;
    private isSidePanelOpen = true;

    private appService: AppService = new AppService();
    private notificationService: NotificationService = new NotificationService();

    get $confirmationBoxRef(): { close: Function; isOpen: boolean} {
        return (this as any).$refs.confirmationBox;
    }

    @Provide('ConfirmAction') private ConfirmAction(
        { title, cancel, confirm, content }: ConfirmationOptions =
        {
            title: this.$t('actions.leaveEditor'),
            cancel: this.$t('actions.cancel'),
            confirm: this.$t('actions.leaveEditor'),
            content: '',
        }
    ): Promise<boolean> {
        return new Promise((res, rej) => {
            const reject = (): void => {
                this.$confirmationBoxRef.close();
                this.confirmation = null;
                return rej(false);
            }
            const resolve = (): void => {
                this.$confirmationBoxRef.close();
                return res(true);
            }
            this.confirmation = { resolve, reject, title, cancel, confirm, content };
            this.$confirmationBoxRef.isOpen = true;
        });
    }

    private async mounted(): Promise<void> {
        try {
            this.promise = this.loadAuth();
            await this.initializeSignalrHub();
        } catch (error) {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('error.loadingResource'),
                copy: this.$t((error as any).message),
            });
        }
    }

    private async loadAuth(): Promise<void> {
        try {
            this.$store.commit('loading');
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            await sdk?.core.userAccessManagement.setPermissions(useCaseId);
            const promises = [
                this.loadApps(),
                this.getNotifications(),
                this.$store.dispatch(
                    'document/GET_FORM_OPTIONS',
                    {
                        selectedLanguage: sdk.translation.uiTranslation.currentLanguage,
                        translations: this.$i18n.messages,
                    }
                ),
            ]
            await Promise.all(promises);
            this.isAppReady = true;
        } catch (error) {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('errorLoad'),
                copy: this.$t(error.message),
            });
        }
        finally{
            this.$store.commit('loading');
        }
    }

    private async getNotifications(): Promise<void> {
        this.$store.commit('loading');

        try {
            const notifications = (await this.notificationService.getAllNotifications(
                {
                    ListNotifications: true,
                    ListTasks: true,
                    GetRead: false,
                    page: 1,
                    size: 300,
                    term: '',
                    useCaseIds: [Number(process.env.VUE_APP_USE_CASE_ID)],
                })).result.items;

            this.$store.commit('notifications/notifications', notifications);
        } catch (err) {
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'notificationsNotLoaded');
        } finally {
            this.$store.commit('loading');
        }
    }

    private async loadApps(): Promise<void> {
        this.$store.commit('loading');
        try {
            const apps = (await this.appService.getApps()).result.items;
            this.applications = this.processApps(apps);
        } catch (error) {
            this.$pui.toast({
                type: 'error',
                title: this.$t('errorLoadingApplications'),
                copy: error.message,
            });
            throw error;
        }
        finally {
            this.$store.commit('loading');
        }
    }

    private processApps(apps: Application[]): MenuEntry[] {
        return apps.map((app: Application): MenuEntry => ({
            id: app.useCaseId,
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.getLogo(app.logo),
            url: app.url ? app.url : undefined,
        })).sort((a: MenuEntry, b: MenuEntry) => {
            const nameA = a?.label?.toLowerCase();
            const nameB = b?.label?.toLowerCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private getLogo(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch (e) {
            return 'app-generic';
        }
    }

    private initializeSignalrHub(): void {
        this.signalConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_EXTERNAL_APP_METADATA_SIGNALR_HUB || 'signalr-hubs', {
                accessTokenFactory: this.getAccessToken,
                withCredentials: true,
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .configureLogging(LogLevel.Debug)
            .withAutomaticReconnect()
            .build();

        this.signalConnection.onclose((error: any) => {
            if (error) {
                console.log(`Connection closed with error: ${error}`);
            }
        });

        this.signalConnection.on (
            'Notification',
            (message: any) => {
                this.$store.dispatch('notifications/addNotification', message);
            });

        this.signalConnection.start()
            .then(() => console.log('Hub connection started'))
            .catch((err: any) => console.log('Error while establishing connection', err));
    }

    private metascope: string | undefined  = process.env.VUE_APP_META_DATA_SCOPE;
    private async getAccessToken(): Promise<any> {
        return (await sdk?.auth.getToken(this.metascope || ''));
    }

}
</script>

<style lang="less" scoped>
@import 'variables.less';
::v-deep .pui-context-menu__content {
  z-index: 1000001;
}
.pui-application-layout__main {
    overflow: auto;
}
.main {
    padding-top: 1rem;
    min-height: 100%;
}

/deep/ .left-panel {
    .pui-side-panel__wrapper .pui-side-panel__main--padding {
        padding: 0;
        overflow: hidden;
    }
}
</style>
<style>
.pui-tabs .flag {
    border: none;
}
</style>
