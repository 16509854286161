
import { CoodeSdk, CoodeSdkOptions, Environments, LogLevel } from '@coode/fe-sdk';

const environment: string | undefined = process.env.VUE_APP_ENVIRONMENT;

const sdkPluginConfig: CoodeSdkOptions = {
    environment: environment as Environments ,
    phraseProjectId: process.env.VUE_APP_PHRASE_PROJECT_ID,
    auth: {
        backEndBaseUrl: process.env.VUE_APP_API_BASE_URL as string,
        backEndScope: process.env.VUE_APP_LFE_SCOPE as string,
        msal: {
            auth: {
                clientId: process.env.VUE_APP_CLIENT_ID || '',
                authority: process.env.VUE_APP_AUTHORITY_URL,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: `${window.location.origin}/authorization/logged-out.html`,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
            system: {
                loggerOptions: {
                    logLevel: LogLevel.Error
                },
            },
        },
    },
    insights: {
        appInsightsConnectionString: process.env.VUE_APP_APP_INSIGHTS_CONNECTION_STRING ?? '',
    }
};

export const sdk = new CoodeSdk(sdkPluginConfig)
