<template>
    <pui-form-input-field
        v-bind="$attrs"
        v-on="listeners"
        v-model.trim="valueProxy"
    />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'debounce-input',
})
export default class DebounceInput extends Vue{
    @Prop(String) readonly value!: string;
    @Prop({ type: Number, default: 400 }) readonly debounceTime!: number;
    @Prop({ type: Number, default: 0 }) readonly debounceLength!: number;
    private debounce: ReturnType<typeof setTimeout> = setTimeout(() => '', 10);

    private get listeners(): { [key: string]: Function | Function[] } {
        const { input, ...listeners } = this.$listeners;
        return listeners;
    }

    private get valueProxy(): string {
        return this.value;
    }
    private set valueProxy(newVal) {
        clearTimeout(this.debounce);
        if (this.value === newVal || newVal.length < this.debounceLength) return;
        this.debounce = setTimeout((): void => {
            this.$emit('input', newVal);
        }, this.debounceTime);
    }
}
</script>
