import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '@/store';
import { ConsequenceService } from '@/services/consequence-service';
import { Category, TranslationParams } from '@/models';
import { getRootTranslationKey, getTranslationForCategory } from '@/utils/translation-helper';

const consequenceService = new ConsequenceService()

interface ConsequenceState {
    consequences: Category[];
}

const state: ConsequenceState = {
    consequences: [],
}

const getters: GetterTree<ConsequenceState, ApplicationState> = {};
const mutations: MutationTree<ConsequenceState> = {
    SET_CONSEQUENCES(state: ConsequenceState, payload: typeof state.consequences) {
        state.consequences = payload;
    }
};
const actions: ActionTree<ConsequenceState, ApplicationState> = {
    async GET_CONSEQUENCES(
        { commit }: ActionContext<ConsequenceState, ApplicationState>,
        params: TranslationParams,
    ): Promise<void> {
        const { result } = await consequenceService.getAll();
        commit('SET_CONSEQUENCES', result.map(consequence => getTranslationForCategory({
            category: consequence,
            rootTranslationKey: getRootTranslationKey('consequences'),
            selectedLanguage: params.selectedLanguage,
            translations: params.translations,
        })));
    }
};

const namespaced = true;

export const consequence: Module<ConsequenceState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
