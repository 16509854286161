import { Vue, Component } from 'vue-property-decorator';
import Permissions from '@/utils/permissions';

@Component({})
export default class ComponentSecurity extends Vue {
    protected permission = new Permissions();
    // new changes for roles
    public readonly hasReviewAccess = this.permission.hasReviewAccess();
    public readonly hasSuperReviewAccess = this.permission.hasSuperReviewAccess();

    public isTranslationReviewerForLanguage(lang: string): boolean {
        return this.permission.isTranslationReviewerForLanguage(lang);
    }
}
