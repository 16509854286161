<template>
    <pui-collapsible
        class="multi-check"
        :open="isOpen"
    >
        <template #collapsibleHeader>
            <slot name="header">
                <div class="header">
                    <span class="header__title">{{ headerTitle }}</span>
                    <span
                        v-if="totalSelectedOptions"
                        class="header__count"
                    >
                        ({{ totalSelectedOptions }})
                    </span>
                    <pui-button
                        small
                        :disabled="!totalSelectedOptions"
                        @click.stop="$emit('clear')"
                    >
                        {{ $t('actions.clear') }}
                    </pui-button>
                </div>
            </slot>
        </template>
        <div class="content">
            <slot name="content" />
        </div>
    </pui-collapsible>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'collapsible-filter',
})
export default class AppliedFilters extends Vue {
    @Prop({ type: Boolean, required: false, default: false }) readonly isOpen!: boolean;
    @Prop({ type: Number, required: true, default: 0 }) readonly totalSelectedOptions!: number;
    @Prop({ type: String, required: true, default: ''}) readonly headerTitle!: string;
}
</script>

<style lang="less" scoped>
.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__title {
        font-family: Roboto-Medium, sans-serif;
        flex: 1;
        text-align: left;
    }

    &__action {
        text-transform: none;
    }
}

.multi-check {
    box-shadow: none;
}
</style>


<style lang="less">
.multi-check {
    .pui-collapsible__header {
        .pui-collapsible__header-content {
            padding-right: 0;
        }
    }
}
</style>
