
const removeQueryOperatorsFromEndOfString = (queryString: string): string => {
    if (queryString.endsWith('&') || queryString.endsWith('?')) {
        return queryString.slice(0, -1);
    }
    return queryString;
}

/* eslint-disable sonarjs/cognitive-complexity */
export function filterToQueryString (filter: Record<string, any> | undefined): string {
    if (!filter) {
        return '';
    }

    let queryString = '?';

    for (const [key, value] of Object.entries(filter)) {
        if (value) {
            if (Array.isArray(value)) {
                if (value.length > 0) {
                    for (const idx in value) {
                        queryString += `${key}[${idx}]=${value[idx]}&`;
                    }
                }
            } else {
                queryString = queryString.concat(`${key}=${value}&`);
            }
        }
    }

    queryString = removeQueryOperatorsFromEndOfString(queryString);

    return queryString;
}


/**
 * Add "EventStatus" filter condition that filters out "CancelledAndArchived" events
 * but allows all other event status values. Modifies given filter object.
 * @param filter
 */

