import { Category } from '@/models/category';
import { Attachment } from '@/models/attachment';
import { Profile } from '@/models';
import { LanguageCode } from '@/utils/translation-helper';

export interface DocumentContent {
    id?: number;
    locale: string;
    title: string;
    whatHappened: string;
    directCauseOfIncident: string;
    whyHasItHappened: string;
    whatCanBeLearned: string;
    actionsTaken: string;
}

export interface Case {
    caseDateTime: string | null;
    categories: Category[];
    locations: Category[];
    fleets: Category[];
    consequences: Category[];
    caseNo?: number;
    caseType?: string;
    actualSeverity: string;
    potentialRisk?: string;
    title?: string;
    hazards: Category[];
    reportingData?: Date;
    id?: number;
    synergiCaseId?: number;
    createdDate?: string;
    createdBy?: Profile;
}

export interface Document {
    id?: number;
    documentContents: DocumentContent[];
    cases: Case[];
    attachments: Attachment[];
    documentNumber?: string;
    status?: string;
    createdDate?: string;
    createdBy?: Profile;
    editedDate?: string;
    editedBy?: Profile;
    highestRisk?: string;
    highestSeverity?: string;
    viewCount: number;
    likeCount: number;
    like: boolean;
    issuedBy: Profile;
    issuedDate: string;
    approvedBy: Profile;
    teaserPictureUri?: string;
    reviewStatus?: DocumentStatus;
}

export interface GetDocumentsQuery {
    dateFrom?: string;
    dateTo?: string;
    Severity?: number[];
    Risk?: number[];
    Hazard?: number[];
    Location?: string[];
    Consequence?: number[];
    Category?: number[];
    Fleet?: number[];
    Editor?: string[];
    Sort?: Sort;
    ReviewStatus?: number[];
    role?: string;
    page: string | number;
    size?: string | number;
    term?: string | number;
}

export interface ReviewStatus{
    documentId?: number;
    reviewStatus?: DocumentStatus;
    commenterRole?: number;
}

/**
 * @DocumentStatus
 * @property Draft - first document status, when document is created
 * @property Published - second document status, when the document is sent for content review
 * @property PendingTranslation - third document status, when the content reviewer approved the content
 * @property NotRelevant - third document status, when the content reviewer rejects the content
 * @property Verified - 4th and final document status when the learning is visible to everyone
 */
export enum DocumentStatus {
    Draft = 'Draft',
    Published = 'Published',
    PendingTranslation = 'PendingTranslation',
    NotRelevant = 'NotRelevant',
    Verified = 'Verified',
}

export enum Sort {
    Date = 0,
    Popularity = 1,
    LoggedInUser = 2
}

export interface DocumentExportParams {
    language: LanguageCode;
    categories: string[];
    technologies: string[];
    hazards: string[];
    consequences: string[];
    actualSeverity: string;
    potentialRisk: string;
}

export interface ExportParams extends DocumentExportParams {
    title: string;
    documentNumber: string;
}
