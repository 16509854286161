import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '@/store';
import { Permissions, Profile } from '@/models';
import { UserService } from '@/services/user-service';

const userService = new UserService();
import { sdk } from '@/utils/fe-sdk/sdk';
export interface UserState {
    profile: Profile;
    users: Profile[];
}

const state: UserState = {
    profile: {
        email: '',
        'family_name': '',
        'given_name': '',
        permissions: [],
        sub: '',
    },
    users: [],
}

const getters: GetterTree<UserState, ApplicationState> = {
    IS_PROFILE(state) {
        return !!state.profile.email;
    },
    HAS_PERMISSION(state) {
        return (permission: Permissions): boolean | undefined => state.profile.permissions?.includes(permission);
    },
    IS_CREATOR(state) {
        return state.profile.permissions?.includes(Permissions.Creator);
    },
    IS_ADMIN(state) {
        return state.profile.permissions?.includes(Permissions.Admin);
    },
};

const mutations: MutationTree<UserState> = {
    SET_PROFILE(state: UserState, profile: Profile) {
        Object.assign(state.profile, profile);
    },
    SET_USERS(state: UserState, users: Profile[]) {
        state.users = users;
    },
};

const actions: ActionTree<UserState, ApplicationState> = {


    async GET_PROFILE({ commit }: ActionContext<UserState, ApplicationState>) {
        const profile: any = await sdk?.localStorage.getPermissionsLocalStorage();
        commit('SET_PROFILE', profile);
    },
    async GET_USERS({ commit }: ActionContext<UserState, ApplicationState>, query) {
        const { result } = await userService.getAll(query);
        commit('SET_USERS', result.items.filter((user) => !!user.kid));
    },
};

const namespaced = true;

export const user: Module<UserState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
