import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { ApplicationState } from '@/store';
import { DocumentService } from '@/services/document-service';
import { Document, GetDocumentsQuery, LikePayload, Pagination, Profile, TranslationParams } from '@/models';
import { formatUrlSearchParams } from '@/utils/helpers/formatters';
import { LikeService } from '@/services/like-service';
import { ReviewStatus } from '@/models/document';
import Permission from '@/utils/permissions';

const documentService: DocumentService = new DocumentService();
const likeService: LikeService = new LikeService();
const documentsPageSize = '4';
const permission: Permission = new Permission();

interface DocumentState {
    documents: Document[];
    document: Document | null;
    editors: Profile[];
    pagination: Pagination;
}

const state: DocumentState = {
    documents: [],
    document: null,
    editors: [],
    pagination: {
        currentPage: 1,
        hasNextPage: false,
        hasPreviousPage: false,
        totalCount: 0,
        totalPages: 1,
    }
}

const getters: GetterTree<DocumentState, ApplicationState> = {};

const mutations: MutationTree<DocumentState> = {
    SET_DOCUMENTS(state, payload: Document[]) {
        state.documents = payload;
    },
    SET_DOCUMENT(state, payload = null) {
        state.document = payload;
    },
    SET_PAGINATION(state, payload) {
        state.pagination = payload;
    },
    SET_EDITORS(state, payload) {
        state.editors = payload;
    },
    SET_LIKE_STATE(state, payload: LikePayload) {
        if (state.document) {
            state.document.like = payload.like;
            state.document.likeCount += payload.like ? 1 : -1;
        } else {
            const document = state.documents.find(({ id }) => id === payload.documentId);
            if (document) {
                document.like = payload.like;
                document.likeCount += payload.like ? 1 : -1;
            }
        }
    }
};


const actions: ActionTree<DocumentState, ApplicationState> = {
    async GET_DOCUMENTS(
        { commit }: ActionContext<DocumentState, ApplicationState>,
        query: GetDocumentsQuery = { page: 1 }
    ) {
        let role = 'User';
        if(permission.hasReviewAccess() && !permission.hasSuperReviewAccess()){
            role = 'Reviewer';
        }
        else if(permission.hasSuperReviewAccess()){
            role = 'SuperAdmin';
        }

        const params = formatUrlSearchParams(query);
        params.append('size', documentsPageSize);
        params.append('role', role);
        const { result } = await documentService.getDocuments({ params });
        const { items, ...pagination } = result;

        commit('SET_DOCUMENTS', items);
        commit('SET_PAGINATION', pagination);
    },
    async GET_DOCUMENT({ commit }: ActionContext<DocumentState, ApplicationState>, paramValue) {
        const { result } = await documentService.getDocument(paramValue);
        commit('SET_DOCUMENT', result);
    },
    async DELETE_DOCUMENT({ commit, state }: ActionContext<DocumentState, ApplicationState>, documentId: number) {
        await documentService.deleteDocument(documentId);
        commit('SET_DOCUMENTS', state.documents.filter((document) => document.id !== documentId));
    },
    async UPDATE_DOCUMENT({ commit }: ActionContext<DocumentState, ApplicationState>, payload: Document) {
        const { result } = await documentService.updateDocument(payload);
        commit('SET_DOCUMENT', result);
    },
    async UPDATE_REVIEWSTATUS({ commit }: ActionContext<DocumentState, ApplicationState>, payload: ReviewStatus) {
        const { result } = await documentService.updateReviewStatus(payload);
        commit('SET_DOCUMENT', result);
    },
    async GET_EDITORS({ commit }: ActionContext<DocumentState, ApplicationState>) {
        const { result } = await documentService.getEditors();
        commit('SET_EDITORS', result);
    },
    async GET_FORM_OPTIONS(
        { dispatch }: ActionContext<DocumentState, ApplicationState>,
        translationParams: TranslationParams
    ) {
        await Promise.all([
            dispatch('category/GET_CATEGORIES', translationParams, { root: true }),
            dispatch('location/GET_LOCATIONS', null, { root: true }),
            dispatch('fleet/GET_FLEETS', translationParams, { root: true }),
            dispatch('hazard/GET_HAZARDS', translationParams, { root: true }),
            dispatch('consequence/GET_CONSEQUENCES', translationParams, { root: true }),
            dispatch('GET_EDITORS'),
        ]);
    },
    async TOGGLE_LIKE({ commit }: ActionContext<DocumentState, ApplicationState>, payload: LikePayload) {
        await likeService.setLike(payload);
        commit('SET_LIKE_STATE', payload);
    },
};

const namespaced = true;

export const document: Module<DocumentState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
