import { ActionContext, ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Category, IdToItemMap, TranslationParams } from '@/models';
import { ApplicationState } from '@/store';
import { CategoryService } from '@/services/category-service';
import { buildMapIdToObject } from '@/utils/helpers/mappers';
import { getRootTranslationKey, getTranslationForCategory } from '@/utils/translation-helper';

const categoryService: CategoryService = new CategoryService();

interface CategoryState {
    categories: Category[];
}

const state: CategoryState = {
    categories: [],
};

const getters: GetterTree<CategoryState, ApplicationState> = {
    CATEGORIES_BY_ID(state: CategoryState) {
        const copy: Category[] = JSON.parse(JSON.stringify(state.categories));
        return copy.reduce(buildMapIdToObject, {} as IdToItemMap<Category>);
    },
};

const mutations: MutationTree<CategoryState> = {
    SET_CATEGORIES(state: CategoryState, payload: typeof state.categories) {
        state.categories = payload;
    }
};

const actions: ActionTree<CategoryState, ApplicationState> = {
    async GET_CATEGORIES(
        { commit }: ActionContext<CategoryState, ApplicationState>,
        params: TranslationParams
    ): Promise<void> {
        const { result } = await categoryService.getAll();
        commit('SET_CATEGORIES', result.map(category => getTranslationForCategory({
            category,
            rootTranslationKey: getRootTranslationKey('categories'),
            selectedLanguage: params.selectedLanguage,
            translations: params.translations,
        })));
    },
};

const namespaced = true;

export const category: Module<CategoryState, ApplicationState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};
